import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionState } from '@/redux';
import { EditIconButton, OnOff, TableColumnType, YesNo } from '@/components';
import { NavLink } from 'react-router-dom';
import { useFiltersFactory } from '@/utils';
import { StringFilterOptions } from '@/core';
import { SubscriptionType } from './SubscriptionListTabPanel';

export function useSubscriptionsColumns(
  subscriptions: SubscriptionState[] | undefined,
  openEdit: (communityId: string) => any,
  type: SubscriptionType,
  stringFilterOptions?: StringFilterOptions,
) {
  const { t } = useTranslation();
  const filters = useFiltersFactory(subscriptions, stringFilterOptions);

  return useMemo<TableColumnType<SubscriptionState>[]>(() => {
    const columns: Array<TableColumnType<SubscriptionState> | false> = [
      {
        title: t('subscriptions.number'),
        key: 'number',
        defaultSortOrder: 'descend',
        ...filters.select((x) => x.number, 'number'),
        sorter: (a, b) => a.number.localeCompare(b.number),
        render: (_, item) => item.number,
        width: '10%',
      },
      {
        title: t('subscriptions.clientName'),
        key: 'clientName',
        ...filters.select((x) => x.clientName, 'clientName'),
        sorter: (a, b) => a.clientName.localeCompare(b.clientName),
        render: (_, { clientId, clientName }) => (
          <NavLink to={`/clients/${clientId}`}>{clientName}</NavLink>
        ),
        width: '15%',
      },
      {
        title: t('subscriptions.communityName'),
        key: 'communityName',
        ...filters.select((x) => x.communityName, 'communityName'),
        sorter: (a, b) => a.communityName.localeCompare(b.communityName),
        render: (_, { communityId, communityName }) => (
          <NavLink to={`/communities/${communityId}`}>{communityName}</NavLink>
        ),
        width: '15%',
      },
      type === 'subscription' && {
        title: t('subscriptions.renovationProjectsEnabled'),
        key: 'renovationProjectsEnabled',
        render: (_, { renovationProjectsEnabled }) => (
          <YesNo>{renovationProjectsEnabled}</YesNo>
        ),
      },
      type === 'subscription' && {
        title: t('subscriptions.newConstructionProjectsEnabled'),
        key: 'newConstructionProjectsEnabled',
        render: (_, { newConstructionProjectsEnabled }) => (
          <YesNo>{newConstructionProjectsEnabled}</YesNo>
        ),
      },
      type === 'subscription' && {
        title: t('subscriptions.pmLiteEnabled'),
        key: 'pmLite',
        render: (_, { pmLiteEnabled }) => <OnOff>{pmLiteEnabled}</OnOff>,
      },
      type === 'special-request' && {
        title: t('subscriptions.renovationFloorplanEnabled'),
        key: 'renovationFloorplanEnabled',
        render: (
          _,
          { renovationFloorplanEnabled, renovationProjectsEnabled },
        ) =>
          renovationProjectsEnabled ? (
            <YesNo>{renovationFloorplanEnabled}</YesNo>
          ) : (
            '-'
          ),
      },
      type === 'special-request' && {
        title: t('subscriptions.renovationRoomEnabled'),
        key: 'renovationRoomEnabled',
        render: (_, { renovationRoomEnabled, renovationProjectsEnabled }) =>
          renovationProjectsEnabled ? (
            <YesNo>{renovationRoomEnabled}</YesNo>
          ) : (
            '-'
          ),
      },
      type === 'special-request' && {
        title: t('subscriptions.newConstructionFloorplanEnabled'),
        key: 'newConstructionFloorplanEnabled',
        render: (
          _,
          { newConstructionFloorplanEnabled, newConstructionProjectsEnabled },
        ) =>
          newConstructionProjectsEnabled ? (
            <YesNo>{newConstructionFloorplanEnabled}</YesNo>
          ) : (
            '-'
          ),
      },
      type === 'special-request' && {
        title: t('subscriptions.newConstructionRoomEnabled'),
        key: 'newConstructionRoomEnabled',
        render: (
          _,
          { newConstructionRoomEnabled, newConstructionProjectsEnabled },
        ) =>
          newConstructionProjectsEnabled ? (
            <YesNo>{newConstructionRoomEnabled}</YesNo>
          ) : (
            '-'
          ),
      },
      {
        title: t('subscriptions.actions'),
        key: 'actions',
        render: (_, { communityId }) => (
          <EditIconButton
            type="link"
            onClick={() => openEdit(communityId)}
            className="table-action"
          />
        ),
      },
    ];
    return columns.filter((x) => !!x) as TableColumnType<SubscriptionState>[];
  }, [t, filters, type, openEdit]);
}
