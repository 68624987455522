import { Button, Divider, Row } from 'antd';
import { Form } from '@/components';
import { useTranslation } from 'react-i18next';
import { useClientLookupSource } from '@/views';
import { useField } from 'formik';
import { useBool } from '@/utils';
import { useEffect, useState } from 'react';

interface Props {
  name: string;
}

export function ReportingClientSelect({ name }: Props) {
  const clientsDataSource = useClientLookupSource();
  const [options, setOptions] = useState<string[]>([]);
  const [{ value }, , { setValue }] = useField<string[]>(name);

  const { t } = useTranslation();
  const [allSelected, setAllSelected, toggleAllSelected] = useBool(true);
  const handleAllSelect = () => {
    if (allSelected) {
      setValue([]);
    } else {
      setValue(options);
    }
    toggleAllSelected();
  };

  useEffect(() => {
    setAllSelected(value?.length === options.length);
  }, [options.length, setAllSelected, value]);

  return (
    <Form.AsyncSelect
      name={name}
      dataSource={clientsDataSource}
      mode="multiple"
      onSearchLoad={(options) => setOptions(options.map((x) => x.value))}
      maxTagCount={2}
      maxTagTextLength={10}
      dropdownRender={(menu) => (
        <>
          {menu}
          <>
            <Divider style={{ margin: '2px' }} />
            <Row justify="center" className="p-2">
              <Button type="link" onClick={handleAllSelect}>
                {t(
                  `reports.homeStatus.${
                    allSelected ? 'unselectAll' : 'selectAll'
                  }`,
                )}
              </Button>
            </Row>
          </>
        </>
      )}
    />
  );
}
