import { useField } from 'formik';
import { useFormLabel } from './FormsI18nContext';

import { FormsGroup } from './FormsGroup';

export type FormsTextProps = { name: string; label?: string };

export const FormsText = ({ label: labelOverride, name }: FormsTextProps) => {
  const [{ value }] = useField(name);
  const label = useFormLabel(name, labelOverride);

  return (
    <FormsGroup label={label} name={name}>
      {value}
    </FormsGroup>
  );
};
