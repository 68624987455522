import { Button, Divider, Row, Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { useGetProjectStepsQuery, ProjectStepLookupState } from '@/redux';
import styles from './FloorplanPersonalization.module.scss';
import { FormsSelectOption } from '@/components/Forms/FormsSelect';
import { isArray, orderBy } from 'lodash';
import { ProjectHomeInfoDto } from '@/core';
import { getStepWithNearestDeadline } from './getStepWithNearestDeadline';
import { useBool } from '@/utils';

interface Props {
  onSelect: (value?: string | string[]) => any;
  steps: ProjectStepLookupState[];
  selected?: string | string[];
  mode?: 'multiple';
}

interface PersonalizationStepsProps {
  projectId: string;
  projectHome?: ProjectHomeInfoDto;
}

export function usePersonalizationSteps({
  projectId,
  projectHome,
}: PersonalizationStepsProps) {
  const { data } = useGetProjectStepsQuery({ ids: projectId });

  const [selected, setSelected] = useState<string | string[]>();
  const steps = useMemo(() => {
    const e = getStepWithNearestDeadline(projectHome, data);
    if (!!e?.id && e?.id !== selected) {
      setSelected(e?.id);
    }
    return orderBy(data, (x) => x.index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, projectHome?.steps]);

  const selectedIdsArray = useMemo(
    () => (isArray(selected) ? selected : selected ? [selected] : undefined),
    [selected],
  );

  const { selectedSteps, isWholeHomeSelected } = useMemo(() => {
    const result = steps.filter((x) => selectedIdsArray?.includes(x.id));
    const isWholeHomeSelected = !!selectedIdsArray?.find((x) => x === 'all');

    return {
      isWholeHomeSelected,
      selectedSteps: isWholeHomeSelected
        ? undefined
        : result.length < 1
        ? null
        : result,
    };
  }, [selectedIdsArray, steps]);

  return {
    selected,
    onSelect: setSelected,
    steps,
    selectedIdsArray,
    selectedSteps,
    isWholeHomeSelected,
  };
}

export function PersonalizationSteps(props: Props) {
  const { steps, onSelect, selected, mode } = props;
  const { t } = useTranslation();
  const [allSelected, setAllSelected, toggleAllSelected] = useBool(false);

  const items = useMemo<FormsSelectOption[]>(
    () => [
      {
        value: 'all',
        label: t('personalization.floorplan.allPersonalizations'),
      },
      ...steps.map((x) => ({
        value: x.id,
        label: x.name,
      })),
    ],
    [t, steps],
  );

  const handleChange = (id: string | string[]) => {
    const ids =
      isArray(id) && id.length > 1
        ? id[id.length - 1] === 'all'
          ? 'all'
          : id.filter((x) => x !== 'all')
        : id;
    onSelect(ids);
    if (isArray(ids) && ids.length === steps.length) {
      setAllSelected(true);
    }
  };

  const handleAllSelect = () => {
    if (allSelected) {
      onSelect([]);
    } else {
      const ids = steps.map((x) => x.id);
      onSelect(ids);
    }
    toggleAllSelected();
  };

  return (
    <Select<string[] | string>
      onChange={handleChange}
      className={styles.personalizationStepsBtn}
      defaultValue={items[0].label}
      value={selected || items[0].value}
      mode={mode}
      maxTagCount={2}
      maxTagTextLength={10}
      maxTagPlaceholder={(omittedValues) => (
        <Tooltip title={omittedValues.map(({ label }) => label).join(', ')}>
          <span>+ {omittedValues.length}</span>
        </Tooltip>
      )}
      dropdownRender={(menu) => (
        <>
          {menu}
          {mode === 'multiple' && (
            <>
              <Divider style={{ margin: '2px' }} />
              <Row justify="center" className="p-2">
                <Button type="link" onClick={handleAllSelect}>
                  {t(
                    `personalization.${
                      allSelected ? 'unselectAll' : 'selectAll'
                    }`,
                  )}
                </Button>
              </Row>
            </>
          )}
        </>
      )}
    >
      {items.map((x) => (
        <Select.Option key={x.value}>{x.label}</Select.Option>
      ))}
    </Select>
  );
}
