import axios from 'axios';
import { Http } from '@/core';
import { Flippo } from '@webinex/flippo';

const axiosInstance = axios.create({
  baseURL: '/api/flippo',
});

axiosInstance.interceptors.request.use(async (request) => {
  const { key, value } = await Http.getAuthHeader();
  request.headers![key] = value;
  return request;
});

export const flippoClient = new Flippo({ axios: axiosInstance });
