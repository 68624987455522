import { AsyncSelect } from '@/components';
import { useState } from 'react';
import { useHomeLookupSource } from '../Homes.Common';
import { useTranslation } from 'react-i18next';

interface Props {
  communityId: string;
  projectId?: string;
  className?: string;
  onSelected: (id: string | undefined) => any;
  selectedId: string | undefined;
  showResidentNames?: boolean;
}

export function useHomeSelectedState(
  initialId?: string,
): Pick<Props, 'onSelected' | 'selectedId'> {
  const [selectedId, onSelected] = useState<string | undefined>(initialId);
  return { selectedId, onSelected };
}

export function SearchProjectHomeInput(props: Props) {
  const {
    communityId,
    projectId,
    className,
    onSelected,
    selectedId,
    showResidentNames,
  } = props;
  const dataSource = useHomeLookupSource(
    communityId,
    projectId,
    showResidentNames,
  );
  const { t } = useTranslation();

  return (
    <AsyncSelect
      className={className}
      value={selectedId}
      onChange={onSelected}
      dataSource={dataSource}
      placeholder={t('developScope.selectHome')}
      preload
      placement="bottomLeft"
    />
  );
}
