import {
  LookupSource,
  LookupOption,
  createEntireLoadLookupSource,
} from '@/components';
import { ItemMenuLookupState, useLazyGetItemMenusQuery } from '@/redux';
import { RtkqSpin } from '@/redux/rtkq';
import { useMemo } from 'react';

export function mapItemMenuLookup(value: ItemMenuLookupState): LookupOption {
  return {
    label: value.name,
    value: value.id,
  };
}

export function useItemMenuLookupSource(
  communityId: string,
  listed?: boolean | null,
  excludeMenuId?: string,
): LookupSource {
  const [fetchList] = useLazyGetItemMenusQuery();

  return useMemo(() => {
    const entireSource = createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList({ communityId, listed, [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) =>
            mapItemMenuLookup(values.find((x) => x.id === id)!),
          ),
      load: () =>
        fetchList({ communityId, listed, [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) => {
            return values
              .filter((x) => x.id !== excludeMenuId)
              .map(mapItemMenuLookup);
          }),
    });

    return { ...entireSource };
  }, [fetchList, communityId, listed, excludeMenuId]);
}
