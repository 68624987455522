import { InputNumber, InputNumberProps } from 'antd';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

export interface PricingInputProps
  extends Pick<
    InputNumberProps<number>,
    'prefix' | 'disabled' | 'placeholder' | 'min' | 'max' | 'precision'
  > {
  initialValue: number | undefined;
  resetOnInitialValueChange: true;
  minWidth?: number;
  onSubmit: (value: number | undefined) => any;
  className?: string;
}

export function PricingInput(props: PricingInputProps) {
  const {
    onSubmit,
    initialValue,
    resetOnInitialValueChange,
    minWidth = 90,
    className,
    ...inputNumberProps
  } = props;
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleBlur = useCallback(() => {
    // eslint-disable-next-line eqeqeq
    value != initialValue && onSubmit(value);
    setValue(initialValue);
  }, [value, initialValue, onSubmit]);

  return (
    <InputNumber
      value={value}
      onChange={setValue}
      onBlur={handleBlur}
      style={{ minWidth }}
      {...inputNumberProps}
      className={classNames('w-100', className)}
    />
  );
}
