import { flippoClient, miscHttp } from '@/core';
import { useEffect, useState } from 'react';

export function useAvatar(reference: string | undefined) {
  const [url, setUrl] = useState<string>();
  const [notFound, setNotFound] = useState<boolean>(!reference);

  function revokeBlobUrl() {
    if (url) {
      URL.revokeObjectURL(url);
    }
  }

  async function fetchAvatar() {
    try {
      const blob = reference!.startsWith('avatar-')
        ? await miscHttp.avatar(reference!)
        : await flippoClient.fetch(reference!);
      setUrl(URL.createObjectURL(blob));
      setNotFound(false);
    } catch {
      setUrl(undefined);
      setNotFound(true);
    }
  }

  useEffect(() => {
    revokeBlobUrl();

    if (!reference) {
      return;
    }

    fetchAvatar();

    return revokeBlobUrl;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference]);

  return { url, notFound };
}
