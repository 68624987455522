import { AsyncSelect } from '@/components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useItemMenuLookupSource } from '../ItemMenu.Common';

interface MenuSelectProps {
  communityId: string;
  menuId?: string;
  setMenuId: (menuId?: string) => void;
}

export function MenuSelect(props: MenuSelectProps) {
  const { communityId, menuId, setMenuId } = props;
  const itemMenuDataSource = useItemMenuLookupSource(communityId);
  const { t } = useTranslation();
  const [value, setValue] = useState<string>('all');

  useEffect(() => {
    setValue(menuId ?? 'all');
  }, [menuId]);

  const onChange = (value?: string) => {
    setMenuId(value === 'all' ? undefined : value);
  };

  return (
    <AsyncSelect
      value={value}
      onChange={onChange}
      dataSource={itemMenuDataSource}
      preload
      options={(options) => [
        { value: 'all', label: t('pricing.settings.allMenus') },
        ...options,
      ]}
      allowClear={false}
    />
  );
}
