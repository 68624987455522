import { StyledRow } from '@/components';
import { formatters } from '@/core';
import { usePersonalizationData } from '@/views';
import { useGetPersonalizationItemsSubtotalAmount } from '@/views/Personalization.Summary/PersonalizationItemList';
import { Col, Row, Tooltip, Typography } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type AmountInfoByType = 'room' | 'floorplan';

interface Props {
  currentRoomAmount: number | undefined;
  isCurrentRoomAmountFinal: boolean | undefined;
  totalHomeAmount: number | undefined;
  isTotalHomeAmountFinal: boolean | undefined;
  totalFloorplanAmount: number | undefined;
  isTotalFloorplanAmountFinal: boolean | undefined;
  byType: AmountInfoByType;
}

export const useGetPersonalizationAmounts = (
  homeId: string,
  projectId: string,
  stepIds: string[] | undefined,
  roomId: string | undefined,
) => {
  const { totalHomeData, floorplanData, roomsData, wholeHomeData } =
    usePersonalizationData({
      homeId,
      projectId,
      stepId: stepIds,
    });

  const { amount: totalHomeAmount, isFinalAmount: isTotalHomeAmountFinal } =
    useGetPersonalizationItemsSubtotalAmount(
      totalHomeData,
      (x) => x.totalResidentPrice,
      true,
      true,
    );

  const selectedRoomData = useMemo(() => {
    return roomId
      ? roomsData.find((x) => x.roomId === roomId)?.value || []
      : wholeHomeData;
  }, [roomId, roomsData, wholeHomeData]);

  const { amount: totalRoomAmount, isFinalAmount: isTotalRoomAmountFinal } =
    useGetPersonalizationItemsSubtotalAmount(
      selectedRoomData,
      (x) => x.totalResidentPrice,
      true,
      true,
    );

  const { amount: floorplanAmount, isFinalAmount: isFloorplanAmountFinal } =
    useGetPersonalizationItemsSubtotalAmount(
      floorplanData,
      (x) => x.totalResidentPrice,
      true,
      true,
    );

  return useMemo(
    () => ({
      totalHomeAmount,
      isTotalHomeAmountFinal,
      floorplanAmount,
      isFloorplanAmountFinal,
      totalRoomAmount,
      isTotalRoomAmountFinal,
    }),
    [
      isFloorplanAmountFinal,
      isTotalHomeAmountFinal,
      isTotalRoomAmountFinal,
      floorplanAmount,
      totalHomeAmount,
      totalRoomAmount,
    ],
  );
};

const { Title, Text } = Typography;

export function PersonalizationAmountInfo(props: Props) {
  const {
    currentRoomAmount,
    isCurrentRoomAmountFinal,
    totalHomeAmount,
    isTotalHomeAmountFinal,
    totalFloorplanAmount,
    isTotalFloorplanAmountFinal,
    byType,
  } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'personalization.amount',
  });

  return (
    <StyledRow justify="end">
      <Col>
        <Row justify="end">
          <Text>
            {t(byType, {
              amount: formatters.price(
                byType === 'floorplan'
                  ? totalFloorplanAmount
                  : currentRoomAmount,
              ),
            })}
          </Text>
          {!(byType === 'floorplan'
            ? isTotalFloorplanAmountFinal
            : isCurrentRoomAmountFinal) && (
            <Tooltip placement="right" title={t('warning')}>
              *
            </Tooltip>
          )}
        </Row>
        <Row justify="end">
          <Title level={4}>
            {t('totalHome', { amount: formatters.price(totalHomeAmount) })}
          </Title>
          {!isTotalHomeAmountFinal && (
            <Tooltip placement="right" title={t('warning')}>
              *
            </Tooltip>
          )}
        </Row>
      </Col>
    </StyledRow>
  );
}
