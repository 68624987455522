import { Modal, Button } from 'antd';
import { Form, spinner } from '@/components';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { GeneratePersonalizationSummaryReportArgs } from '@/redux';
import { FileExcelOutlined } from '@ant-design/icons';
import { PersonalizationSummaryReportFilterForm } from './PersonalizationSummaryReportFilterForm';
import {
  Language,
  ReportOrientationType,
  reportsHttp,
  ReportSizeType,
} from '@/core';
import { useCallback } from 'react';
import { browserFileDownload } from '@/utils';

const schema = Yup.object().shape({
  clientIds: Yup.array().of(Yup.string().defined()),
  communityIds: Yup.array().of(Yup.string().defined()),
  projectIds: Yup.array()
    .of(Yup.string().nullable().required('Project ID is required'))
    .min(1, 'Project must not be empty')
    .required(),
  homeIds: Yup.array()
    .of(Yup.string().nullable().required('Home ID is required'))
    .min(1, 'Home must not be empty')
    .required(),
  language: Yup.mixed<Language>().nullable().required(),
  orientation: Yup.mixed<ReportOrientationType>().nullable().required(),
  size: Yup.mixed<ReportSizeType>().nullable().required(),
});

export type GeneratePersonalizationSummaryReportFormValue = OmitIndex<
  Yup.InferType<typeof schema>
>;

const INITIAL_VALUE: GeneratePersonalizationSummaryReportFormValue = {
  clientIds: [],
  communityIds: [],
  projectIds: [],
  homeIds: [],
  language: Language.English,
  orientation: ReportOrientationType.Portrait,
  size: ReportSizeType.Letter,
};

interface Props {
  onClose: () => any;
}

const useSubmit = (props: Props) => {
  const { onClose } = props;
  return useCallback(
    (args: GeneratePersonalizationSummaryReportArgs) => {
      spinner.show();
      reportsHttp
        .generatePersonalizationSummary(args)
        .then((file) => {
          browserFileDownload(file);
        })
        .finally(() => {
          onClose();
          spinner.hide();
        });
    },
    [onClose],
  );
};

export function GeneratePersonalizationSummaryReportModal(props: Props) {
  const { onClose: onCancel } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'reports.personalizationSummary',
  });

  const handleSubmit = useSubmit(props);

  return (
    <Modal
      title={t('title')}
      visible
      onCancel={onCancel}
      footer={[
        <Button key="back" type="default" onClick={onCancel}>
          {t('cancel')}
        </Button>,
        <Form.Submit
          uid="reports-personalizationSummary"
          key="submit"
          type="primary"
          icon={<FileExcelOutlined />}
        >
          {t('runReport')}
        </Form.Submit>,
      ]}
    >
      <Form.Formik<GeneratePersonalizationSummaryReportFormValue>
        uid="reports-personalizationSummary"
        i18n="reports.personalizationSummary"
        initialValues={INITIAL_VALUE}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <PersonalizationSummaryReportFilterForm />
      </Form.Formik>
    </Modal>
  );
}
