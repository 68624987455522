import { MAX_MONEY_VALUE, MIN_MONEY_VALUE } from '@/utils';
import { useTranslation } from 'react-i18next';
import { PricingInput } from '../Pricing.Common';
import { formatters } from '@/core';

export interface PriceInputProps {
  initialValue: number | undefined;
  resetOnInitialValueChange: true;
  disabled: boolean;
  onSubmit: (value: number | undefined) => any;
  calculated: boolean;
  tbd?: boolean;
  minWidth?: number;
  className?: string;
}

export function PriceInput(props: PriceInputProps) {
  const {
    initialValue,
    disabled,
    onSubmit,
    calculated,
    tbd,
    minWidth,
    className,
  } = props;
  const { t } = useTranslation();

  const initialInputValue = calculated ? undefined : initialValue;
  let placeholder = calculated ? formatters.price(initialValue) : undefined;
  placeholder = tbd ? t('pricing.tbd') : placeholder;

  return (
    <PricingInput
      initialValue={initialInputValue}
      onSubmit={onSubmit}
      resetOnInitialValueChange
      disabled={disabled}
      max={MAX_MONEY_VALUE}
      min={MIN_MONEY_VALUE}
      placeholder={placeholder}
      precision={2}
      prefix="$"
      minWidth={minWidth}
      className={className}
    />
  );
}
