import React from 'react';
import classNames from 'classnames';
import styles from './TextBox.module.scss';

export interface TextBoxProps {
  label: string;
  children: React.ReactNode;
  className?: string;
}

export const TextBox = (props: TextBoxProps) => {
  const { children, label, className } = props;

  return (
    <div className={classNames(className, styles.container)}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{children}</div>
    </div>
  );
};
