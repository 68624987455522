import { usePricingSettingsColumns } from './usePricingSettingsColumns';
import { Table } from 'antd';
import { PricingSettingsMatrixRowDto } from '@/core';
import { usePricingSettingsMatrixState } from './usePricingSettingsMatrixState';
import { useExcelExport } from '@/components/Table/useExcelExport';

export interface Props {
  communityId: string;
  menuId: string | undefined;
  className?: string;
}

export function PricingSettingsListPanel(props: Props) {
  const { className, communityId, menuId } = props;
  const matrixState = usePricingSettingsMatrixState(communityId, menuId);
  const columns = usePricingSettingsColumns(matrixState);
  const { value } = matrixState;
  const { exportAction } = useExcelExport(columns, value);

  return (
    <div className={className}>
      {exportAction}
      <Table<PricingSettingsMatrixRowDto>
        columns={columns}
        dataSource={value}
        rowKey={(row) => row.categories.map((x) => x.id).join()}
        pagination={false}
        scroll={{ x: 'auto' }}
        sticky={{ offsetHeader: 56 }}
      />
    </div>
  );
}
