import { useTranslation } from 'react-i18next';
import {
  FloorplanPersonalizationPanel,
  HomesOfferingsReportButtonPanel,
  PersonalizationSteps,
  ProjectContext,
  RoomPersonalizationPanel,
  usePersonalizationSteps,
} from '@/views';
import { Col, Radio, RadioChangeEvent, Row, Space } from 'antd';
import { useState } from 'react';
import { AppstoreOutlined, ExpandOutlined } from '@ant-design/icons';
import styles from './PersonalizationTab.module.scss';
import { useGetProjectContextQuery, useGetProjectHomeInfoQuery } from '@/redux';
import classNames from 'classnames';
import {
  PersonalizationAmountInfo,
  useGetPersonalizationAmounts,
} from './RoomPersonalization/PersonalizationAmountInfo';

interface Props {
  homeId: string;
  projectId: string;
  communityId: string;
  className?: string;
}

export function PersonalizationTabs(props: Props) {
  const { className, communityId, homeId, projectId } = props;
  const { t } = useTranslation();
  const [tab, setTab] = useState<string>('floorplan');
  const { data: projectContext } = useGetProjectContextQuery(
    {
      id: projectId,
    },
    { skip: !projectId },
  );

  const onChange = (e: RadioChangeEvent) => {
    setTab(e.target.value);
  };

  var { data: projectHome } = useGetProjectHomeInfoQuery({
    id: projectId,
    homeId,
  });

  const steps = usePersonalizationSteps({ projectId, projectHome });
  const { selectedSteps, isWholeHomeSelected } = steps;

  const [roomId, setRoomId] = useState<string>();

  const {
    totalHomeAmount,
    isTotalHomeAmountFinal,
    floorplanAmount,
    isFloorplanAmountFinal,
    isTotalRoomAmountFinal,
    totalRoomAmount,
  } = useGetPersonalizationAmounts(
    homeId,
    projectId,
    isWholeHomeSelected ? undefined : selectedSteps?.map((x) => x!.id),
    roomId,
  );

  if (!projectContext) {
    return null;
  }

  return (
    <>
      <Row className={className} justify="space-between">
        <Col span={18} className={classNames(tab === 'room' && 'mb-4')}>
          <Row gutter={[10, 5]}>
            <Col>
              <Radio.Group
                value={tab}
                onChange={onChange}
                className={styles.tab}
              >
                <Radio.Button value="floorplan">
                  <Space size="small">
                    <ExpandOutlined />
                    {t('personalization.tabs.floorplan')}
                  </Space>
                </Radio.Button>
                <Radio.Button value="room">
                  <Space size="small">
                    <AppstoreOutlined />
                    {t('personalization.tabs.rooms')}
                  </Space>
                </Radio.Button>
              </Radio.Group>
            </Col>
            <Col span={12}>
              <PersonalizationSteps {...steps} mode="multiple" />
            </Col>
            <Col>
              <HomesOfferingsReportButtonPanel
                projectId={projectId}
                homeId={homeId}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          {selectedSteps !== null && (
            <PersonalizationAmountInfo
              isTotalHomeAmountFinal={isTotalHomeAmountFinal}
              totalHomeAmount={totalHomeAmount}
              currentRoomAmount={totalRoomAmount}
              isCurrentRoomAmountFinal={isTotalRoomAmountFinal}
              isTotalFloorplanAmountFinal={isFloorplanAmountFinal}
              totalFloorplanAmount={floorplanAmount}
              byType={tab as any}
            />
          )}
        </Col>
      </Row>
      {tab === 'floorplan' && (
        <ProjectContext.Provider value={projectContext}>
          <FloorplanPersonalizationPanel
            homeId={homeId}
            projectId={projectId}
            communityId={communityId}
            steps={selectedSteps}
          />
        </ProjectContext.Provider>
      )}
      {tab === 'room' && (
        <ProjectContext.Provider value={projectContext}>
          <RoomPersonalizationPanel
            communityId={communityId}
            homeId={homeId}
            projectId={projectId}
            steps={selectedSteps}
            isWholeHomeSelected={isWholeHomeSelected}
            onRoomSelect={setRoomId}
          />
        </ProjectContext.Provider>
      )}
    </>
  );
}
