import { Form } from '@/components';
import { useHomeLookupSource } from '@/views';
import { useField } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
  communityIds?: string[];
  projectIds?: string[];
}

export function ActivityHomeSelect(props: Props) {
  const { name, communityIds, projectIds } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'activity.table.filter.placeholders',
  });
  const homeDataSource = useHomeLookupSource(communityIds, projectIds);
  const [, , { setValue }] = useField(name);

  useEffect(() => {
    setValue(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityIds, projectIds]);

  return (
    <Form.AsyncSelect
      key={(communityIds ?? []).concat(projectIds ?? []).join()}
      label={false}
      dataSource={homeDataSource}
      name={name}
      placeholder={t('home')}
      mode="multiple"
    />
  );
}
