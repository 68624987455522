import { useCallback, useMemo, useState } from 'react';

export function useBool(
  initialValue: boolean,
): [value: boolean, setValue: (value: boolean) => any, toggle: () => any] {
  const [value, setValue] = useState(initialValue);
  const toggle = useCallback(() => setValue((prev) => !prev), [setValue]);

  return useMemo(() => [value, setValue, toggle], [value, toggle, setValue]);
}
