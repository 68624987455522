import { SortResult } from '@/components/Collections.Paging/useSorting';
import {
  Http,
  AddCommunityDto,
  CommunityDto,
  TreeNodeDto,
  CommunityStatus,
  UpdateCommunityDto,
  ItemDto,
  UpdateItemDto,
  CreateItemDto,
  ItemDetailsDto,
  DeleteItemBookletLinkDto,
  AddItemBookletLinkDto,
  UserCommunityAccessDetailsDto,
  CommunityAssignmentDto,
  CommunityUserDto,
  UpdateCommunityAssignmentDto,
  UploadItemsDto,
  DownloadedFile,
  asFileResponse,
  CommunityContextDto,
  Paged,
  Paging,
  CommunitiesFilterParams,
  CommunityUserFilterParams,
  AddItemExternalLinkDto,
  DeleteItemExternalLinkDto,
} from '@/core';
import { guard } from '@/utils';
import { isArray } from 'lodash';
import qs from 'qs';

class CommunitiesHttp extends Http {
  public async getAll(
    paging: Paging,
    clientId?: string[] | string,
    deactivated?: boolean | null,
    filters?: CommunitiesFilterParams,
    sorters?: SortResult,
  ) {
    const response = await this._axios.get<Paged<CommunityDto>>(
      // TODO: Add ability to pass multiple clients filter to backend
      `/api/community?${qs.stringify({
        clientIds: isArray(clientId) ? clientId : [clientId],
        deactivated,
        ...paging,
        ...filters,
        ...sorters,
      })}`,
    );
    return response.data;
  }

  public async getAvailableForProjects(clientId?: string) {
    const response = await this._axios.get<CommunityDto[]>(
      `/api/community/available-for-projects?${qs.stringify({
        clientId,
      })}`,
    );
    return response.data;
  }

  public async get(id: string) {
    guard.notNull(id, 'id');
    const response = await this._axios.get<CommunityDto>(
      `/api/community/${id}`,
    );
    return response.data;
  }

  public async getLastCreated(clientId?: string) {
    const response = await this._axios.get<CommunityDto>(
      `/api/community/last?${qs.stringify({
        clientId,
      })}`,
    );
    return response.data;
  }

  public async create(request: AddCommunityDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.post<string>('/api/community', request);
    return response.data;
  }

  public async update(request: UpdateCommunityDto) {
    guard.notNull(request, 'request');
    await this._axios.put('/api/community', request);
  }

  public async changeStatus(id: string, status: CommunityStatus) {
    guard.notNull(id, 'id');
    guard.notNull(status, 'status');
    const response = await this._axios.post<string>(
      '/api/community/update-status',
      {
        id,
        status,
      },
    );
    return response.data;
  }

  public async createLocation(communityId: string, treeNode: TreeNodeDto) {
    guard.notNull(communityId, 'communityId');
    guard.notNull(treeNode, 'treeNode');

    await this._axios.post(`/api/community/${communityId}/location`, treeNode);
  }

  public async updateLocation(communityId: string, treeNode: TreeNodeDto) {
    guard.notNull(communityId, 'communityId');
    guard.notNull(treeNode, 'treeNode');
    guard.notNull(treeNode.id, 'treeNode.id');

    await this._axios.put(
      `/api/community/${communityId}/location/${treeNode.id}`,
      treeNode,
    );
  }

  public async unlistLocation(communityId: string, id: string) {
    guard.notNull(communityId, 'communityId');
    guard.notNull(id, 'id');

    await this._axios.put(
      `/api/community/${communityId}/location/${id}/unlist`,
    );
  }

  public async getLocations(
    communityId: string,
    includeUnlistedIds?: string[],
  ) {
    guard.notNull(communityId, 'communityId');

    const query = qs.stringify({ includeUnlistedIds });
    const response = await this._axios.get<TreeNodeDto[]>(
      `/api/community/${communityId}/location?${query}`,
    );

    return response.data;
  }

  public async getAllItems(communityId: string, isGeo?: boolean) {
    guard.notNull(communityId, 'communityId');
    const query = qs.stringify({ communityId, isGeo });
    const url = `/api/item?${query}`;
    const response = await this._axios.get<ItemDto[]>(url);
    return response.data;
  }

  public async getItem(id: string, listed?: boolean | null) {
    guard.notNull(id, 'id');
    const url = `/api/item/${id}?${qs.stringify({ listed })}`;
    const response = await this._axios.get<ItemDetailsDto>(url);
    return response.data;
  }

  public async createItem(request: CreateItemDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.post<string>(`/api/item`, request);
    return response.data;
  }

  public async updateItem(request: UpdateItemDto) {
    guard.notNull(request, 'request');
    await this._axios.put(`/api/item`, request);
  }

  public async unlistItem(id: string) {
    guard.notNull(id, 'id');
    await this._axios.put(`/api/item/${id}/unlist`);
  }

  public async addBookletLink(request: AddItemBookletLinkDto) {
    guard.notNull(request, 'request');
    await this._axios.post(`/api/item/${request.itemId}/booklet-link`, request);
  }

  public async addExternalLink(request: AddItemExternalLinkDto) {
    guard.notNull(request, 'request');
    await this._axios.post(
      `/api/item/${request.itemId}/external-link`,
      request,
    );
  }

  public async deleteBookletLink(request: DeleteItemBookletLinkDto) {
    guard.notNull(request, 'request');
    await this._axios.put(`/api/item/${request.itemId}/booklet-link`, request);
  }

  public async deleteExternalLink(request: DeleteItemExternalLinkDto) {
    guard.notNull(request, 'request');
    await this._axios.put(`/api/item/${request.itemId}/external-link`, request);
  }

  public async getAccesses(userIds: string[]) {
    guard.notNull(userIds, 'userIds');

    if (userIds.length === 0) {
      return [];
    }

    const response = await this._axios.post<UserCommunityAccessDetailsDto[]>(
      `/api/community/accesses`,
      userIds,
    );

    return response.data;
  }

  public async assignUser(request: CommunityAssignmentDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.post<string>(
      `/api/community/${request.communityId}/assign-user`,
      request,
    );
    return response.data;
  }

  public async getAllUsers(
    communityId: string,
    paging: Paging,
    filters?: CommunityUserFilterParams,
    sorting?: SortResult,
  ) {
    guard.notNull(communityId, 'communityId');
    const response = await this._axios.get<Paged<CommunityUserDto>>(
      `/api/community/${communityId}/users?${qs.stringify({
        ...paging,
        ...filters,
        ...sorting,
      })}`,
    );
    return response.data;
  }

  public async updateCommunityUser(request: UpdateCommunityAssignmentDto) {
    guard.notNull(request, 'request');
    await this._axios.put(
      `/api/community/${request.communityId}/user`,
      request,
    );
  }

  public async removeUserAssignment(args: {
    communityId: string;
    userId: string;
  }) {
    guard.notNull(args, 'args');
    await this._axios.put(
      `/api/community/${args.communityId}/user-assignment`,
      args,
    );
  }

  public async removeAmenity(id: string) {
    guard.notNull(id, 'id');

    await this._axios.delete(`/api/community/amenity/${id}`);
  }

  public async uploadItems(request: UploadItemsDto) {
    guard.notNull(request, 'request');
    await this._axios.post(`/api/item/upload`, request);
  }

  public async downloadUploadSample(): Promise<DownloadedFile> {
    const response = await this._axios.get(`/api/item/download-upload-sample`, {
      responseType: 'blob',
    });
    return asFileResponse(response);
  }

  public async getContext(id: string) {
    guard.notNull(id, 'communityId');
    const response = await this._axios.get<CommunityContextDto>(
      `/api/community/context/${id}`,
    );
    return response.data;
  }
}

export const communitiesHttp = new CommunitiesHttp();
