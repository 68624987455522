import { Form } from '@/components';
import { PricingMarkupDefaultsDto } from '@/core';
import { UpdatePricingMarkupDefaultsArgs } from '@/redux';
import { useCallback } from 'react';
import * as Yup from 'yup';

interface Props {
  value: UpdatePricingMarkupDefaultsArgs;
  defaultMarkups?: PricingMarkupDefaultsDto;
  onSubmit: (value: UpdatePricingMarkupDefaultsArgs) => any;
  autoSubmit: boolean;
}

const SCHEMA = Yup.object().shape({
  constructionMarkup: Yup.number().min(0).max(10000).nullable(),
  residentMarkup: Yup.number().min(0).max(10000).nullable(),
  salesTax: Yup.number().min(0).max(10000).nullable(),
});

function useHandleSubmit(props: Props) {
  const { value, onSubmit } = props;
  return useCallback(
    (formValue: UpdatePricingMarkupDefaultsArgs) => {
      if (
        formValue.constructionMarkup !== value.constructionMarkup ||
        formValue.residentMarkup !== value.residentMarkup ||
        formValue.salesTax !== value.salesTax
      ) {
        return onSubmit(formValue);
      }
    },
    [value, onSubmit],
  );
}

export function PricingSettingsDefaultsForm(props: Props) {
  const { value, defaultMarkups } = props;
  const onSubmit = useHandleSubmit(props);

  return (
    <Form.Formik<UpdatePricingMarkupDefaultsArgs>
      initialValues={value}
      onSubmit={onSubmit}
      uid="pricing-settings-defaults"
      i18n="pricing.settings.defaults"
      validationSchema={SCHEMA}
      enableReinitialize
    >
      {(formik) => (
        <>
          <Form.Number
            name="constructionMarkup"
            prefix="%"
            min={0}
            max={10000}
            precision={6}
            onBlur={formik.submitForm}
            placeholder={defaultMarkups?.construction?.toFixed(6)}
          />
          <Form.Number
            name="residentMarkup"
            prefix="%"
            min={0}
            max={10000}
            precision={6}
            onBlur={formik.submitForm}
            placeholder={defaultMarkups?.resident?.toFixed(6)}
          />
          <Form.Number
            name="salesTax"
            prefix="%"
            min={0}
            max={10000}
            precision={6}
            onBlur={formik.submitForm}
            placeholder={defaultMarkups?.salesTax?.toFixed(6)}
          />
        </>
      )}
    </Form.Formik>
  );
}
