import {
  Space,
  Table as AntdTable,
  TableColumnType as AntdTableColumnType,
  TablePaginationConfig,
  TableProps as AntdTableProps,
  Row,
} from 'antd';
import { useColumnsSelect } from './ColumnsControl/useColumnsSelect';
import { Key, useEffect, useMemo, useState } from 'react';
import {
  ColumnsType,
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from 'antd/lib/table/interface';
import { useExcelExport } from './useExcelExport';
import { array } from '@/utils';
import { VList } from 'virtuallist-antd';
import { ResetFiltersButton } from './ResetFiltersButton';

export type TableColumnType<T> = Omit<AntdTableColumnType<T>, 'key'> & {
  key: string;
  children?: ColumnsType<T>;
  exportValue?: (item: T) => React.ReactNode;
};

const DEFAULT_PAGE_SIZE = 10;

const DEFAULT_PAGE_SIZE_OPTIONS: string[] = [
  '10',
  '20',
  '50',
  '100',
  '200',
  '500',
  '1000',
  '10000',
];

const EMPTY_COLUMN = {
  key: 'empty',
};

export const PAGINATION_DEFAULT = {
  defaultPageSize: DEFAULT_PAGE_SIZE,
  pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
  showSizeChanger: true,
};

const systemColumns: Key[] = ['actions'];

export interface TableProps<T>
  extends Omit<AntdTableProps<T>, 'columns' | 'title'> {
  name: string;
  actions?: React.ReactElement;
  columns: TableColumnType<T>[];
  hideDefaultActions?: boolean;
  useVirtualList?: boolean;
  onDataSourceChange?: () => void;
  onFiltersReset?: () => void;
  defaultHiddenColumnKeys?: string[];
}

export function Table<T extends object>(props: TableProps<T>) {
  const {
    name,
    loading,
    columns,
    actions,
    dataSource,
    onChange,
    hideDefaultActions,
    useVirtualList,
    onDataSourceChange,
    onFiltersReset,
    defaultHiddenColumnKeys,
    rowSelection,
  } = props;
  const [visibleData, setVisibleData] = useState<readonly T[]>([]);

  const virtualListComponents = useMemo(() => {
    return VList({
      height: 1000,
    });
  }, []);

  const {
    visibleColumns,
    isLoading: isColumnsLoading,
    columnsSelect,
  } = useColumnsSelect(name, columns, defaultHiddenColumnKeys);

  const exportColumnKeys = useMemo(
    () =>
      visibleColumns
        ? visibleColumns
            .map((column) => column.key!)
            .filter((key) => !systemColumns.includes(key))
        : array.empty<string>(),
    [visibleColumns],
  );

  const { exportAction } = useExcelExport(
    columns,
    visibleData,
    exportColumnKeys,
    'mb-3',
  );

  useEffect(() => {
    setVisibleData([]);
    if (onDataSourceChange) onDataSourceChange();
  }, [dataSource, onDataSourceChange]);

  const handleChange = (
    paging: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorters: SorterResult<T> | SorterResult<T>[],
    extra: TableCurrentDataSource<T>,
  ) => {
    setVisibleData(extra.currentDataSource);
    onChange && onChange(paging, filters, sorters, extra);
  };

  const [tableKey, setTableKey] = useState(0);

  const resetFilters = () => {
    setTableKey((tableKey) => tableKey + 1);
    onFiltersReset && onFiltersReset();
  };

  const isNeedToAddEmptyColumnForSelectableGrid = useMemo(() => {
    return visibleColumns?.length === columns.length
      ? false
      : rowSelection && !visibleColumns?.find((x) => !x.width);
  }, [columns.length, rowSelection, visibleColumns]);

  const isRowSelectionAvailable = useMemo(() => {
    return !!visibleColumns && visibleColumns.length > 0;
  }, [visibleColumns]);

  return (
    <AntdTable<T>
      {...props}
      key={tableKey}
      title={(current) => {
        if (current.length > 0 && visibleData.length === 0) {
          setTimeout(() => setVisibleData(current), 0);
        }

        if (hideDefaultActions && !actions) return null;

        return (
          <Row justify="space-between">
            <Space>
              {!hideDefaultActions && columnsSelect}
              {!hideDefaultActions && exportAction}
              <ResetFiltersButton handleReset={resetFilters} />
              {actions}
            </Space>
          </Row>
        );
      }}
      scroll={props.scroll === undefined ? { x: 'auto' } : props.scroll}
      pagination={
        props.pagination === undefined ? PAGINATION_DEFAULT : props.pagination
      }
      loading={loading || isColumnsLoading}
      rowSelection={isRowSelectionAvailable ? rowSelection : undefined}
      columns={
        isNeedToAddEmptyColumnForSelectableGrid
          ? [...visibleColumns!, EMPTY_COLUMN]
          : visibleColumns
      }
      onChange={handleChange}
      sticky={{ offsetHeader: 56 }}
      components={useVirtualList ? virtualListComponents : undefined}
    />
  );
}
