import { FieldRow, Form, LookupOption } from '@/components';
import * as Yup from 'yup';
import { AddSubscriptionArgs } from '@/redux';
import { Col } from 'antd';
import { useClientLookupSource } from '@/views';
import { FormSelectCommunity } from './FormSelectCommunity';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { SubscriptionType } from '../Subscriptions.List/SubscriptionListTabPanel';

const schema = Yup.object().shape({
  clientId: Yup.string().nullable().required(),
  communityId: Yup.string().nullable().required(),
  pmLiteEnabled: Yup.bool().required(),
  renovationProjectsEnabled: Yup.bool().required(),
  newConstructionProjectsEnabled: Yup.bool().required(),
  renovationFloorplanEnabled: Yup.bool().required(),
  renovationRoomEnabled: Yup.bool().required(),
  newConstructionFloorplanEnabled: Yup.bool().required(),
  newConstructionRoomEnabled: Yup.bool().required(),
});

export type SubscriptionFormValue = AddSubscriptionArgs;

export const INITIAL_SUBSCRIPTION: SubscriptionFormValue = {
  clientId: null!,
  communityId: null!,
  newConstructionProjectsEnabled: false,
  pmLiteEnabled: false,
  renovationProjectsEnabled: false,
  renovationFloorplanEnabled: false,
  renovationRoomEnabled: false,
  newConstructionFloorplanEnabled: false,
  newConstructionRoomEnabled: false,
};

interface Props {
  value: SubscriptionFormValue;
  onSubmit: (value: SubscriptionFormValue) => any;
  disabledClient?: boolean;
  disabledCommunity?: boolean;
  initialClient?: LookupOption;
  initialCommunity?: LookupOption;
  type: SubscriptionType;
}

export function SubscriptionDetailsForm(props: Props) {
  const { value, onSubmit } = props;

  return (
    <Form.Formik<SubscriptionFormValue>
      uid="subscription-details"
      i18n="subscriptions.details"
      initialValues={value}
      validationSchema={schema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <FormikFieldSet {...props} />
    </Form.Formik>
  );
}

function FormikFieldSet(props: Props) {
  const clientDataSource = useClientLookupSource();
  const {
    disabledClient,
    disabledCommunity,
    initialClient,
    initialCommunity,
    type,
  } = props;
  const {
    values: { renovationProjectsEnabled, newConstructionProjectsEnabled },
    setFieldValue,
    touched,
    isSubmitting,
  } = useFormikContext<SubscriptionFormValue>();

  useEffect(() => {
    if (isSubmitting) return;
    if (!renovationProjectsEnabled || touched.renovationProjectsEnabled) {
      setFieldValue('renovationFloorplanEnabled', renovationProjectsEnabled);
      setFieldValue('renovationRoomEnabled', renovationProjectsEnabled);
    }

    if (
      !newConstructionProjectsEnabled ||
      touched.newConstructionProjectsEnabled
    ) {
      setFieldValue(
        'newConstructionFloorplanEnabled',
        newConstructionProjectsEnabled,
      );
      setFieldValue(
        'newConstructionRoomEnabled',
        newConstructionProjectsEnabled,
      );
    }
  }, [
    renovationProjectsEnabled,
    newConstructionProjectsEnabled,
    setFieldValue,
    touched.renovationProjectsEnabled,
    touched.newConstructionProjectsEnabled,
    isSubmitting,
  ]);
  return (
    <>
      <FieldRow>
        <Col span={12}>
          <Form.AsyncSelect
            dataSource={clientDataSource}
            name="clientId"
            required
            preloadInitial={initialClient?.value}
            disabled={disabledClient}
          />
        </Col>
      </FieldRow>
      <FieldRow>
        <Col span={12}>
          <FormSelectCommunity
            name="communityId"
            preloadInitial={initialCommunity}
            disabled={disabledCommunity}
            initialClientId={initialClient?.value}
          />
        </Col>
      </FieldRow>
      {type === 'subscription' && (
        <>
          <Form.Switch name="renovationProjectsEnabled" />
          <Form.Switch name="newConstructionProjectsEnabled" />
          <Form.Switch name="pmLiteEnabled" />
        </>
      )}
      {type === 'special-request' && (
        <>
          {renovationProjectsEnabled && (
            <>
              <Form.Switch name="renovationFloorplanEnabled" />
              <Form.Switch name="renovationRoomEnabled" />
            </>
          )}
          {newConstructionProjectsEnabled && (
            <>
              <Form.Switch name="newConstructionFloorplanEnabled" />
              <Form.Switch name="newConstructionRoomEnabled" />
            </>
          )}
        </>
      )}
    </>
  );
}
