import { TabPanel, AsyncSelect } from '@/components';
import { Space } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHomeLookupSource } from '../Homes.Common';
import { PricingHomeOverrideListPanel } from './PricingHomeOverrideListPanel';
import styles from './PricingHomeOverrideListPanel.module.scss';

interface Props {
  communityId: string;
}

export function PricingHomeOverrideListTabPanel(props: Props) {
  const { communityId } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pricing.homeOverrides',
  });
  const [selectedHomeIds, setSelectedHomeIds] = useState<string[]>();
  const homeDataSource = useHomeLookupSource(communityId);

  return (
    <TabPanel>
      <TabPanel.Title>{t('title')}</TabPanel.Title>
      <TabPanel.Body>
        <Space direction="vertical" style={{ width: '25%' }} size="large">
          <AsyncSelect
            value={selectedHomeIds as any}
            onChange={setSelectedHomeIds as any}
            dataSource={homeDataSource}
            placeholder={t('home')}
            mode="multiple"
          />
        </Space>
        <PricingHomeOverrideListPanel
          className={classNames('mt-4', styles.panel)}
          communityId={communityId}
          homeIds={selectedHomeIds}
        />
      </TabPanel.Body>
    </TabPanel>
  );
}
