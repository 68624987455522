import { FieldRow, Form } from '@/components';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
}

export function ActivityPerformedAtFilter(props: Props) {
  const { name } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'activity.table.filter.placeholders.performedAt',
  });

  return (
    <FieldRow>
      <Col span={12}>
        <Form.Date
          label={false}
          name={`${name}.startDate`}
          placeholder={t('startDate')}
          allowClear={true}
        />
      </Col>
      <Col span={12}>
        <Form.Date
          label={false}
          name={`${name}.endDate`}
          placeholder={t('endDate')}
          allowClear={true}
        />
      </Col>
    </FieldRow>
  );
}
