import {
  LookupOption,
  LookupSource,
  createEntireLoadLookupSource,
} from '@/components';
import { RtkqSpin, useLazyGetReportingProjectsListQuery } from '@/redux';
import { Typography } from 'antd';
import { useMemo } from 'react';

const { Text } = Typography;

function mapProjectLookup(
  value: {
    id: string;
    name: string;
    communityName?: string;
  },
  showCommunityName: boolean,
): LookupOption {
  const { name, communityName } = value;

  return {
    _search: name,
    value: value.id,
    label: showCommunityName ? (
      <span>
        <div>
          <Text> {name}</Text>
        </div>
        <div>
          <Text type="secondary">{communityName}</Text>
        </div>
      </span>
    ) : (
      name
    ),
  };
}

export function useReportingProjectsLookupSource(
  showCommunityName: boolean,
  communityId?: string | string[],
  clientId?: string | string[],
): LookupSource {
  const [fetchList] = useLazyGetReportingProjectsListQuery();

  return useMemo(() => {
    const entireSource = createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList({ communityId, clientId, [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) =>
            mapProjectLookup(
              values.find((x) => x.id === id)!,
              showCommunityName,
            ),
          ),
      load: () =>
        fetchList({ communityId, clientId, [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) =>
            values.map((x) => mapProjectLookup(x, showCommunityName)),
          ),
    });

    return { ...entireSource, searchField: '_search' };
  }, [fetchList, communityId, showCommunityName, clientId]);
}
