import {
  ProjectStepLookupState,
  useGetPersonalizationCategoriesQuery,
} from '@/redux';
import { isEmpty } from 'lodash';
import { RoomPersonalizationCategory } from './RoomPersonalizationCategory';
import { RoomPersonalizationSpecialRequests } from './RoomPersonalizationSpecialRequests';
import { CategoryDto, CommunityPermissions } from '@/core';
import {
  useCommunityContext,
  useIsFinalized,
  useProjectContext,
  useStepDeadlinePassed,
} from '@/views';
import { Tag, Typography } from 'antd';
import styles from './RoomPersonalization.module.scss';
import classNames from 'classnames';
const { Text } = Typography;

interface RoomPersonalizationItemsProps {
  homeId: string;
  projectId: string;
  step?: ProjectStepLookupState;
  roomId?: string;
  collapsed: boolean;
  compact?: boolean;
  className?: string;
}
const flattenCategories = (
  categories: CategoryDto[],
  parentName: string = '',
): CategoryDto[] => {
  return categories.flatMap((category) => {
    const fullName = parentName
      ? `${parentName} - ${category.name}`
      : category.name;
    const flattenedChildCategories = flattenCategories(
      category.children,
      fullName,
    );
    return [
      {
        id: category.id,
        name: fullName,
        custom: category.custom,
        listed: category.listed,
        children: [],
      },
      ...flattenedChildCategories,
    ];
  });
};

export function RoomPersonalizationCategoryList(
  props: RoomPersonalizationItemsProps,
) {
  const { homeId, projectId, step, roomId, collapsed, compact, className } =
    props;
  const { data } = useGetPersonalizationCategoriesQuery({
    homeId,
    projectId,
    roomId,
    stepId: step?.id,
    floorplanPersonalization: false,
  });

  const stepDeadlinePassed = useStepDeadlinePassed(homeId, projectId, step);

  const isFinalized = useIsFinalized(homeId, projectId, step?.id);
  const { assert } = useCommunityContext();
  const { isActive } = useProjectContext();
  const readonly =
    step?.id === undefined ||
    !isActive ||
    stepDeadlinePassed === null ||
    stepDeadlinePassed ||
    !!isFinalized;

  const flattenedCategories = flattenCategories(data ?? []);
  return (
    <div className={className}>
      {step && (
        <div className={classNames(styles.step, 'mb-2')}>
          <Tag color="blue">
            <Text>{step.name}</Text>
          </Tag>
        </div>
      )}
      {!isEmpty(flattenedCategories) &&
        flattenedCategories!.map((category) => (
          <RoomPersonalizationCategory
            key={category.id}
            homeId={homeId}
            projectId={projectId}
            step={step}
            roomId={roomId}
            category={category}
            collapsed={collapsed}
            readonly={
              readonly ||
              !assert(CommunityPermissions.Personalization.Manage, true)
            }
            compact={compact}
          />
        ))}
      <RoomPersonalizationSpecialRequests
        projectId={projectId}
        homeId={homeId}
        stepId={step?.id}
        collapsed={collapsed}
        roomId={roomId}
        readonly={
          readonly ||
          !assert(
            CommunityPermissions.Personalization.SpecialRequestComment,
            true,
          )
        }
        compact={compact}
      />
    </div>
  );
}
