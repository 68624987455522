import { useTranslation } from 'react-i18next';
import { CommunityUserState, useGetCommunityUsersQuery } from '@/redux';
import {
  DefaultNumberSorting,
  Table,
  TabPanel,
  useDefaultTablePaging,
  useFilters,
  useSorting,
} from '@/components';
import {
  useCommunityAssignmentsColumns,
  AddAssignmentButton,
  useEditAssignment,
  Assert,
} from '@/views';
import { CommunityPermissions, CommunityUserFilterParams } from '@/core';
import { useMemo } from 'react';

interface Props {
  clientId: string;
  communityId: string;
}

export function CommunityAssignmentsListPanel(props: Props) {
  const { clientId, communityId } = props;
  const { setTotal, paging, params } = useDefaultTablePaging();
  const {
    filters,
    onFiltersChange,
    resetFilters,
    stringFilterOptions,
    setStringFilterOptions,
  } = useFilters<CommunityUserFilterParams>([
    'name',
    'email',
    'number',
    'phone',
  ]);
  const { sorting, onSortingChange } =
    useSorting<CommunityUserState>(DefaultNumberSorting);

  const { data, isFetching } = useGetCommunityUsersQuery({
    communityId,
    paging: params,
    filters,
    sorting,
  });

  const communityUsers = useMemo(() => {
    if (data) {
      setTotal(data.total);
      setStringFilterOptions(data.stringFilterOptions);
    }
    return data?.items;
  }, [data, setStringFilterOptions, setTotal]);

  const { t } = useTranslation();
  const { openEdit, editModal } = useEditAssignment(props);

  const columns = useCommunityAssignmentsColumns(
    communityUsers,
    openEdit,
    stringFilterOptions,
  );

  return (
    <TabPanel>
      <TabPanel.Title>{t('communityUsers.title')}</TabPanel.Title>
      <TabPanel.Actions>
        <Assert permission={CommunityPermissions.Users.Manage} active>
          <AddAssignmentButton communityId={communityId} clientId={clientId} />
        </Assert>
      </TabPanel.Actions>
      <TabPanel.Body>
        <Table<CommunityUserState>
          name="communityUsers"
          loading={isFetching}
          columns={columns}
          dataSource={communityUsers}
          rowKey={(row) => row.id}
          pagination={paging}
          onChange={(_, filters, sorting) => {
            onFiltersChange(filters);
            onSortingChange(sorting);
          }}
          onFiltersReset={resetFilters}
        />
        {editModal}
      </TabPanel.Body>
    </TabPanel>
  );
}
