import { notify, TabPanel } from '@/components';
import {
  UpdatePricingMarkupDefaultsArgs,
  useGetPricingSettingsMatrixQuery,
  useUpdateMarkupDefaultsMutation,
} from '@/redux';
import { Col, Divider, Row } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuSelect } from './MenuSelect';
import { PricingSettingsDefaultsForm } from './PricingSettingsDefaultsForm';
import { PricingSettingsListPanel } from './PricingSettingsListPanel';

interface Props {
  communityId: string;
}

function useUpdateDefaultMarkups() {
  const [update] = useUpdateMarkupDefaultsMutation();
  return useCallback(
    (value: UpdatePricingMarkupDefaultsArgs) =>
      update(value)
        .unwrap()
        .then(() => notify.success.t('pricing.settings.saved')),
    [update],
  );
}

export function PricingSettingsListTabPanel(props: Props) {
  const { communityId } = props;
  const { t } = useTranslation();
  const [menuId, setMenuId] = useState<string>();
  const { data } = useGetPricingSettingsMatrixQuery({ communityId, menuId });
  const { data: allMenusData } = useGetPricingSettingsMatrixQuery(
    { communityId },
    { skip: !!data?.id },
  );
  const value: UpdatePricingMarkupDefaultsArgs = useMemo(
    () => ({
      communityId,
      constructionMarkup: data?.id
        ? data?.defaultMarkups.construction
        : undefined,
      residentMarkup: data?.id ? data?.defaultMarkups.resident : undefined,
      salesTax: data?.id ? data?.defaultMarkups.salesTax : undefined,
      menuId,
    }),
    [
      communityId,
      data?.defaultMarkups.construction,
      data?.defaultMarkups.resident,
      data?.defaultMarkups.salesTax,
      data?.id,
      menuId,
    ],
  );
  const handleSubmit = useUpdateDefaultMarkups();

  return (
    <TabPanel>
      <TabPanel.Title>{t('pricing.settings.title')}</TabPanel.Title>
      <TabPanel.Body>
        {data && (
          <>
            <Row className="mb-2">
              <Col span={8}>
                <MenuSelect
                  communityId={communityId}
                  menuId={menuId}
                  setMenuId={setMenuId}
                />
              </Col>
              <Divider />
            </Row>
            <Row>
              <Col span={8}>
                <PricingSettingsDefaultsForm
                  value={value}
                  onSubmit={handleSubmit}
                  autoSubmit
                  defaultMarkups={allMenusData?.defaultMarkups}
                />
              </Col>
            </Row>
          </>
        )}
        <PricingSettingsListPanel
          className="mt-4"
          communityId={communityId}
          menuId={menuId}
        />
      </TabPanel.Body>
    </TabPanel>
  );
}
