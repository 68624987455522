import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { SpecialRequestDetailsModal } from './SpecialRequestDetailsModal';
import { AddSpecialRequestArgs, useAddSpecialRequestMutation } from '@/redux';
import { notify } from '@/components';
import { SpecialRequestStatus } from '@/core';
import { Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useCommunityContext, useProjectContext } from '@/views';

interface Props {
  stepId?: string;
  projectId: string;
  homeId: string;
  isFloorplan?: boolean;
  roomId?: string;
  categoryId?: string;
  disabledCategory?: boolean;
  asItem?: boolean;
}

const useSubmit = (onClose: () => any, asItem?: boolean) => {
  const [add] = useAddSpecialRequestMutation();

  return (args: AddSpecialRequestArgs) =>
    add({
      ...args,
      position: args.isFloorplan ? { x: 10, y: 10 } : undefined,
      asItem: asItem,
    })
      .unwrap()
      .then(() => {
        notify.success.t('specialRequests.added');
        onClose();
      });
};

function useInitialValue({
  stepId,
  projectId,
  homeId,
  isFloorplan,
  roomId,
  categoryId,
}: Props) {
  return useMemo<AddSpecialRequestArgs>(
    () => ({
      name: '',
      stepId: stepId!,
      categoryId: categoryId ?? null!,
      imageActions: [],
      projectId,
      homeId,
      status: SpecialRequestStatus.Pending,
      isFloorplan: isFloorplan ?? false,
      roomId: roomId,
      fee: false,
    }),
    [stepId, categoryId, projectId, homeId, isFloorplan, roomId],
  );
}

export function AddSpecialRequestButton(props: Props) {
  const {
    stepId,
    projectId,
    homeId,
    isFloorplan,
    roomId,
    disabledCategory,
    asItem,
  } = props;
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const submit = useSubmit(() => setOpenModal(false), asItem);
  const value = useInitialValue(props);
  const { type } = useProjectContext();
  const {
    hasNewConstrFloorplanSubscription,
    hasNewConstrRoomSubscription,
    hasRenoFloorplanSubscription,
    hasRenoRoomSubscription,
  } = useCommunityContext();
  const canAddSpecialRequest = isFloorplan
    ? (type === 'NewConstruction' && hasNewConstrFloorplanSubscription) ||
      (type === 'Renovation' && hasRenoFloorplanSubscription)
    : (type === 'NewConstruction' && hasNewConstrRoomSubscription) ||
      (type === 'Renovation' && hasRenoRoomSubscription);

  return canAddSpecialRequest ? (
    <>
      <Button
        type="primary"
        className="me-2"
        onClick={() => setOpenModal(true)}
        icon={<ExclamationCircleOutlined />}
      >
        {t('personalization.floorplan.addSpecialRequest')}
      </Button>
      {openModal && (
        <SpecialRequestDetailsModal
          title={t('specialRequests.addTitle')}
          stepId={stepId!}
          projectId={projectId}
          homeId={homeId}
          onSubmit={submit}
          onClose={() => setOpenModal(false)}
          value={value}
          isFloorplan={isFloorplan}
          roomId={roomId}
          disabledCategory={disabledCategory}
        />
      )}
    </>
  ) : (
    <></>
  );
}
