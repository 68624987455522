import { FC, PropsWithChildren } from 'react';
import { ReactChildren } from '@/utils';
import { HomeOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import styles from './Page.module.scss';

export type PagePathProps = PropsWithChildren<{ href?: string }>;
export const PagePath: FC<PagePathProps> = () => <></>;

export const PagePaths: React.FC<PropsWithChildren<{}>> = () => {
  return <></>;
};

export type PagePathsProps = PropsWithChildren<{}>;

export const PagePathsRenderer: React.FC<PagePathsProps> = ({ children }) => {
  const pathsProps = ReactChildren.of(children).find(PagePath).propsMany();

  if (pathsProps.length === 0) {
    return null;
  }

  return (
    <Breadcrumb className={styles.paths}>
      <Breadcrumb.Item>
        <NavLink to="/">
          <HomeOutlined />
        </NavLink>
      </Breadcrumb.Item>
      {pathsProps.map((path, index) => (
        <Breadcrumb.Item key={index}>
          {!path.href && path.children}
          {path.href && <NavLink to={path.href}>{path.children}</NavLink>}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};
