import { Form, LookupOption } from '@/components';
import { SubscriptionDetailsState } from '@/redux';
import { Button, Modal } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { mapCommunityLookup, mapClientLookup } from '@/views';
import {
  INITIAL_SUBSCRIPTION,
  SubscriptionDetailsForm,
  SubscriptionFormValue,
} from './SubscriptionDetailsForm';
import { SubscriptionType } from '../Subscriptions.List/SubscriptionListTabPanel';

interface Props {
  value?: SubscriptionDetailsState;
  onSave: (value: SubscriptionFormValue) => any;
  onCancel?: () => any;
  title: React.ReactNode;
  saveBtnText: React.ReactNode;
  disabledClient?: boolean;
  disabledCommunity?: boolean;
  disabledCancel?: boolean;
  type: SubscriptionType;
}

function useUpdatedSubscriptionValue({ value }: Props) {
  return useMemo<SubscriptionFormValue | undefined>(
    () =>
      value
        ? {
            clientId: value.clientId,
            communityId: value.communityId,
            newConstructionProjectsEnabled:
              value.newConstructionProjectsEnabled,
            pmLiteEnabled: value.pmLiteEnabled,
            renovationProjectsEnabled: value.renovationProjectsEnabled,
            renovationFloorplanEnabled: value.renovationFloorplanEnabled,
            renovationRoomEnabled: value.renovationRoomEnabled,
            newConstructionFloorplanEnabled:
              value.newConstructionFloorplanEnabled,
            newConstructionRoomEnabled: value.newConstructionRoomEnabled,
          }
        : undefined,
    [value],
  );
}

export function SubscriptionDetailsModal(props: Props) {
  const {
    value,
    title,
    saveBtnText,
    onSave,
    onCancel,
    disabledClient,
    disabledCommunity,
    disabledCancel,
    type,
  } = props;
  const { t } = useTranslation();

  const updatedSubscription = useUpdatedSubscriptionValue(props);

  const initialClientValue: LookupOption | undefined = useMemo(
    () =>
      value
        ? mapClientLookup({ name: value.clientName, id: value.clientId })
        : undefined,
    [value],
  );

  const initialCommunityValue: LookupOption | undefined = useMemo(
    () =>
      value
        ? mapCommunityLookup({
            name: value.communityName,
            id: value.communityId,
          })
        : undefined,
    [value],
  );

  const initialValue = value ? updatedSubscription : INITIAL_SUBSCRIPTION;

  return (
    <Modal
      title={title}
      visible
      closable={!disabledCancel}
      onCancel={onCancel}
      footer={[
        <Button
          key="back"
          type="default"
          onClick={onCancel}
          disabled={disabledCancel}
          hidden={disabledCancel}
        >
          {t('subscriptions.details.cancel')}
        </Button>,
        <Form.Submit uid="subscription-details" key="submit" type="primary">
          {saveBtnText}
        </Form.Submit>,
      ]}
    >
      <SubscriptionDetailsForm
        onSubmit={onSave}
        value={initialValue!}
        disabledClient={disabledClient}
        disabledCommunity={disabledCommunity}
        initialClient={initialClientValue}
        initialCommunity={initialCommunityValue}
        type={type}
      />
    </Modal>
  );
}
