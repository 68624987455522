import { EnumValue } from '@/components';
import { ItemMenuItemType } from '@/core';
import { enumValues } from '@/utils';
import { Select } from 'antd';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  initialValue: ItemMenuItemType;
  resetOnInitialValueChange: true;
  onSubmit?: (value: ItemMenuItemType) => any;
  disabled: boolean;
  className?: string;
}

export function PricingItemType(props: Props) {
  const { initialValue, onSubmit, disabled, className } = props;
  const [itemType, setItemType] = useState(initialValue);

  useEffect(() => {
    setItemType(initialValue);
  }, [initialValue]);

  const handleSubmit = useCallback(
    (itemType: ItemMenuItemType) => {
      setItemType(itemType);
      if (onSubmit) onSubmit(itemType);
    },
    [onSubmit],
  );

  return (
    <Select<ItemMenuItemType>
      className={classNames('w-100', className)}
      value={itemType}
      labelInValue={false}
      onChange={handleSubmit}
      showSearch={false}
      disabled={disabled}
    >
      {enumValues(ItemMenuItemType).map((value) => (
        <Select.Option key={value}>
          <EnumValue type={ItemMenuItemType} value={value} />
        </Select.Option>
      ))}
    </Select>
  );
}
