import { notify } from '@/components';
import { PricingMarkup } from '@/core';
import {
  PricingSettingsMatrixRowState,
  useDeletePricingMarkupMutation,
  useGetPricingSettingsMatrixQuery,
  useSavePricingMarkupMutation,
} from '@/redux';
import { array } from '@/utils';
import { clone, isEmpty, orderBy, setWith } from 'lodash';
import {
  useState,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';

function isEmptySaleTax(markup: PricingMarkup) {
  if (markup.saleTax == null) {
    return true;
  }

  return isEmpty(markup.saleTax.applyTo) && markup.saleTax.value == null;
}

function useSave(communityId: string, menuId?: string) {
  const [save] = useSavePricingMarkupMutation();

  return useCallback(
    ({ categories, value }: PricingSettingsMatrixRowState) => {
      const categoryId = categories.at(-1)!.id;
      const saleTax = isEmptySaleTax(value!) ? undefined : value!.saleTax;

      return save({
        categoryId,
        communityId,
        menuId,
        value: { ...value!, saleTax },
      })
        .unwrap()
        .then(() => notify.success.t('pricing.settings.saved'));
    },
    [save, communityId, menuId],
  );
}

function useRemove(communityId: string, menuId?: string) {
  const [remove] = useDeletePricingMarkupMutation();

  return useCallback(
    ({ categories }: PricingSettingsMatrixRowState) =>
      remove({ categoryId: categories.at(-1)!.id, communityId, menuId })
        .unwrap()
        .then(() => notify.success.t('pricing.settings.saved')),
    [remove, communityId, menuId],
  );
}

function setImmutable<TState extends object, TValue>(
  state: TState,
  path: string,
  value: TValue,
): TState {
  return setWith<TState>(clone(state), path, value, (v) => clone(v) ?? {});
}

function isCategoriesMatch(
  x: PricingSettingsMatrixRowState,
  y: PricingSettingsMatrixRowState,
) {
  return (
    x.categories[0].id === y.categories[0].id &&
    x.categories[1]?.id === y.categories[1]?.id &&
    x.categories[2]?.id === y.categories[2]?.id
  );
}

function isEmptyItem(item: PricingSettingsMatrixRowState) {
  return (
    item.value?.construction == null &&
    item.value?.resident == null &&
    isEmpty(item.value?.saleTax?.applyTo) &&
    item.value?.saleTax?.value == null
  );
}

function useUpdateValue(
  value: PricingSettingsMatrixRowState[],
  setValue: Dispatch<SetStateAction<PricingSettingsMatrixRowState[]>>,
  communityId: string,
  menuId: string | undefined,
) {
  const remove = useRemove(communityId, menuId);
  const save = useSave(communityId, menuId);

  return useCallback(
    (
      item: PricingSettingsMatrixRowState,
      field: string,
      inputValue: any | undefined,
    ) => {
      const newItem = setImmutable(item, `value.${field}`, inputValue);
      setValue(value.map((x) => (isCategoriesMatch(x, item) ? newItem : x)));
      isEmptyItem(newItem) ? remove(newItem) : save(newItem);
    },
    [setValue, value, remove, save],
  );
}

function usePricingOrderedValues(
  items: PricingSettingsMatrixRowState[],
): PricingSettingsMatrixRowState[] {
  return useMemo(() => {
    return orderBy(items, [
      (x) => x.categories[0].name,
      (x) => x.categories[1]?.name ?? '-',
      (x) => x.categories[2]?.name ?? '-',
    ]);
  }, [items]);
}

export function usePricingSettingsMatrixState(
  communityId: string,
  menuId: string | undefined,
) {
  const { data } = useGetPricingSettingsMatrixQuery({ communityId, menuId });
  const { data: allMenusData } = useGetPricingSettingsMatrixQuery(
    { communityId },
    { skip: !!data?.id },
  );
  const {
    rows = array.empty<PricingSettingsMatrixRowState>(),
    defaultMarkups,
  } = data?.id
    ? data
    : { defaultMarkups: allMenusData?.defaultMarkups, rows: data?.rows };

  const ordered = usePricingOrderedValues(rows);
  const [value, setValue] = useState(ordered);
  useEffect(() => {
    if (ordered.length > 0) setValue(ordered);
  }, [ordered]);

  const updateValue = useUpdateValue(value, setValue, communityId, menuId);
  return useMemo(
    () => ({
      value,
      defaults: defaultMarkups ?? {},
      setValue,
      updateValue,
    }),
    [value, defaultMarkups, setValue, updateValue],
  );
}
