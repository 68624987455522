import { Col, Row, Space, Switch, TableProps } from 'antd';
import {
  SpecialRequestPriceMatrixRowState,
  UpdateSpecialRequestArgs,
  useGetSpecialRequestDetailsQuery,
  useGetSpecialRequestPriceMatrixQuery,
  useUpdateSpecialRequestMutation,
} from '@/redux';
import { useSpecialRequestPricingColumns } from './useSpecialRequestPricingColumns';
import { useBool } from '@/utils';
import { useMemo, useState } from 'react';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Assert } from '../Communities.Common';
import { CommunityPermissions } from '@/core/permissions.communities/communityPermissions';
import { SpecialRequestDetailsModal } from '../Personalization.Details/SpecialRequest/SpecialRequestDetailsModal';
import { notify, useDefaultTablePaging, useFilters } from '@/components';

import { SpecialRequestPriceMatrixFilterParams } from '@/core';

import { Table } from '@/components';
import { PRICING_DEFAULT_PAGE_SIZE_OPTIONS } from '../Pricing.List';

interface Props {
  communityId: string;
  homeIds?: string[];
  projectIds?: string[];
  className?: string;
}

function useSpecialRequestPricingOrderedValues(
  items: SpecialRequestPriceMatrixRowState[],
): SpecialRequestPriceMatrixRowState[] {
  return useMemo(() => {
    return orderBy(items, [
      (x) => x.home.name,
      (x) => x.categories[0].name,
      (x) => x.categories[1]?.name ?? '-',
      (x) => x.categories[2]?.name ?? '-',
    ]);
  }, [items]);
}

function useRowValues(props: Props) {
  const { communityId, projectIds, homeIds } = props;

  const { setTotal, paging, params } = useDefaultTablePaging();
  const {
    filters,
    onFiltersChange,
    resetFilters,
    stringFilterOptions,
    setStringFilterOptions,
  } = useFilters<SpecialRequestPriceMatrixFilterParams>([
    'homeName',
    'constructionNumber',
    'name',
    'category',
    'subcategory1',
    'subcategory2',
    'subcontractor',
    'residentFilter',
  ]);

  const { data } = useGetSpecialRequestPriceMatrixQuery({
    projectIds,
    communityId,
    paging: params,
    filters,
  });

  const { rows } = data ?? {};
  const ordered = useSpecialRequestPricingOrderedValues(rows?.items || []);

  const items = useMemo(() => {
    if (rows) {
      setTotal(rows.total);
      setStringFilterOptions(rows.stringFilterOptions);
    }

    return homeIds?.length! > 0
      ? ordered.filter((x) => homeIds?.includes(x.home.id))
      : ordered;
  }, [homeIds, ordered, rows, setStringFilterOptions, setTotal]);

  return {
    pricing: items,
    paging,
    filterOptions: stringFilterOptions,
    onFiltersChange,
    resetFilters,
  };
}

const useSubmit = (onClose: () => any) => {
  const [add] = useUpdateSpecialRequestMutation();

  return (args: UpdateSpecialRequestArgs) => {
    add(args)
      .unwrap()
      .then(() => {
        notify.success.t('specialRequests.saved');
        onClose();
      })
      .finally(onClose);
  };
};

export function PricingSpecialRequestListPanel(props: Props) {
  const { className, projectIds, communityId } = props;
  const { pricing, paging, onFiltersChange, resetFilters, filterOptions } =
    useRowValues(props);
  const { t } = useTranslation();
  const [collapseCost, , toggleCollapseCost] = useBool(false);

  const [currentData, setCurrentData] =
    useState<SpecialRequestPriceMatrixRowState[]>();

  const handleChange: TableProps<SpecialRequestPriceMatrixRowState>['onChange'] =
    (_pagination, _filters, _sorter, extra) => {
      setCurrentData(extra.currentDataSource);
      onFiltersChange(_filters);
    };
  //It is required to pass filtered data in the table to validly calculate empty cells
  const values = useMemo(
    () => (currentData ? currentData : pricing),
    [pricing, currentData],
  );

  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string>();

  const columns = useSpecialRequestPricingColumns(
    communityId,
    values,
    collapseCost,
    (specialRequestId: string) => {
      setSelectedItemId(specialRequestId);
      setOpenEditModal(true);
    },
    paging,
    projectIds,
    filterOptions,
  );

  const { data: selectedSpecialRequest, isFetching } =
    useGetSpecialRequestDetailsQuery(
      { id: selectedItemId ?? '' },
      { skip: !selectedItemId },
    );

  const submit = useSubmit(() => setOpenEditModal(false));

  return (
    <div className={className}>
      <Assert
        permission={CommunityPermissions.BaseCostAndOwnerPrice.FullAccess}
        active
      >
        <Row className="mb-4">
          <Col span={12}>
            <Space direction="horizontal" size={50}>
              <Space>
                <Switch checked={collapseCost} onChange={toggleCollapseCost} />
                {t('pricing.specialRequest.collapseCost')}
              </Space>
            </Space>
          </Col>
        </Row>
      </Assert>
      <Table<SpecialRequestPriceMatrixRowState>
        name="SpecialRequestPricing"
        onChange={handleChange}
        columns={columns}
        dataSource={pricing}
        rowKey={(row) => row.id}
        pagination={{
          ...paging,
          pageSizeOptions: PRICING_DEFAULT_PAGE_SIZE_OPTIONS,
        }}
        scroll={{ x: 1400 }}
        onFiltersReset={resetFilters}
        defaultHiddenColumnKeys={[
          'subcontractor',
          'resident',
          'constructionNumber',
        ]}
      />
      {openEditModal && selectedSpecialRequest && !isFetching && (
        <SpecialRequestDetailsModal
          isEdit
          title={t('specialRequests.editTitle')}
          stepId={selectedSpecialRequest.step.id}
          projectId={selectedSpecialRequest.projectId}
          homeId={selectedSpecialRequest.homeId}
          onSubmit={submit}
          onClose={() => setOpenEditModal(false)}
          value={{
            ...selectedSpecialRequest,
            stepId: selectedSpecialRequest.step.id,
            categoryId: selectedSpecialRequest.category.category.id,
          }}
          preloadValue={{
            value: selectedSpecialRequest.category.category.id,
            label: selectedSpecialRequest.category.category.name,
          }}
          position={selectedSpecialRequest.position}
          number={selectedSpecialRequest.number}
        />
      )}
    </div>
  );
}
