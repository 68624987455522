import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ResidentState,
  useDeleteResidentMutation,
  useGetResidentsListQuery,
} from '@/redux';
import {
  confirmation,
  DeleteIconButton,
  EditIconButton,
  notify,
  TableColumnType,
} from '@/components';
import { useFiltersFactory, PhoneView } from '@/utils';
import { Space } from 'antd';
import { Assert } from '../Communities.Common';
import { CommunityPermissions } from '@/core';

export function useResidentColumns(
  homeId: string,
  setId: (id: string) => any,
  readonly: boolean,
) {
  const { t } = useTranslation();
  const { data: residents } = useGetResidentsListQuery({ homeId });
  const filters = useFiltersFactory(residents);
  const [remove] = useDeleteResidentMutation();

  const handleDelete = useCallback(
    (id: string) => {
      const perform = () =>
        remove({ id })
          .unwrap()
          .then(() => notify.success.t('residents.deleted'));
      confirmation.delete({ key: 'residents.deletedName' }, perform);
    },
    [remove],
  );

  return useMemo<TableColumnType<ResidentState>[]>(() => {
    return [
      {
        title: t('residents.name'),
        key: 'residents.name',
        ...filters.plainText((x) => x.name),
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (_, item) => item.name,
      },
      {
        title: t('residents.email'),
        key: 'residents.email',
        render: (_, item) => item.email,
      },
      {
        title: t('residents.phone'),
        key: 'residents.phone',
        width: '10%',
        render: (_, { phone }) => <PhoneView value={phone} />,
      },
      {
        title: t('residents.notes'),
        key: 'residents.notes',
        ellipsis: true,
        render: (_, item) => <div className="text-multiline">{item.notes}</div>,
      },
      {
        title: t('residents.actions'),
        key: 'actions',
        width: '10%',
        render: (_, { id }) => (
          <>
            {!readonly && (
              <Assert permission={CommunityPermissions.Residents.Manage} active>
                <Space size="large">
                  <EditIconButton
                    type="link"
                    onClick={() => setId(id)}
                    className="table-action"
                  />
                  <DeleteIconButton
                    onClick={() => handleDelete(id)}
                    className="table-action"
                  />
                </Space>
              </Assert>
            )}
          </>
        ),
      },
    ];
  }, [t, filters, readonly, setId, handleDelete]);
}
