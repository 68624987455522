import { SubscriptionState, useGetSubscriptionsListQuery } from '@/redux';
import {
  DefaultNumberSorting,
  Table,
  TabPanel,
  useDefaultTablePaging,
  useFilters,
  useSorting,
} from '@/components';
import { useSubscriptionsColumns } from './useSubscriptionsColumns';
import { useEditSubscriptionAction } from '../Subscriptions.Details/useEditSubscriptionAction';
import { useTranslation } from 'react-i18next';
import { AddSubscriptionButton } from '../Subscriptions.Details';
import { useMemo } from 'react';
import { SubscriptionFilterParams } from '@/core';

export type SubscriptionType = 'subscription' | 'special-request';

type SubscriptionListTabPanelProps = {
  showCreateButton?: boolean;
  type: SubscriptionType;
};

export function SubscriptionListTabPanel(props: SubscriptionListTabPanelProps) {
  const { showCreateButton, type } = props;
  const { setTotal, paging, params } = useDefaultTablePaging();

  const defaultFilter: SubscriptionFilterParams | undefined = useMemo(() => {
    return type === 'special-request'
      ? {
          newConstructionProjectsEnabled: true,
          renovationProjectsEnabled: true,
        }
      : undefined;
  }, [type]);

  const {
    filters,
    onFiltersChange,
    resetFilters,
    stringFilterOptions,
    setStringFilterOptions,
  } = useFilters<SubscriptionFilterParams>(
    ['number', 'clientName', 'communityName'],
    defaultFilter,
  );
  const { sorting, onSortingChange } =
    useSorting<SubscriptionState>(DefaultNumberSorting);

  const { data, isFetching } = useGetSubscriptionsListQuery({
    paging: params,
    filters,
    sorting,
  });

  const subscriptions = useMemo(() => {
    if (data) {
      setTotal(data.total);
      setStringFilterOptions(data.stringFilterOptions);
    }
    return data?.items;
  }, [data, setStringFilterOptions, setTotal]);

  const { t } = useTranslation();
  const { editModal, openEdit } = useEditSubscriptionAction(type);
  const columns = useSubscriptionsColumns(
    subscriptions,
    openEdit,
    type,
    stringFilterOptions,
  );

  return (
    <TabPanel min="auto">
      <TabPanel.Title>{t('subscriptions.title')}</TabPanel.Title>
      <TabPanel.Actions>
        {showCreateButton && <AddSubscriptionButton />}
      </TabPanel.Actions>
      <TabPanel.Body>
        <Table<SubscriptionState>
          name="Subscriptions"
          loading={isFetching}
          columns={columns}
          dataSource={subscriptions}
          rowKey={(row) => row.communityId}
          pagination={paging}
          onChange={(_, filters, sorting) => {
            onFiltersChange(filters);
            onSortingChange(sorting);
          }}
          onFiltersReset={() => resetFilters(defaultFilter)}
        />
        {editModal}
      </TabPanel.Body>
    </TabPanel>
  );
}
