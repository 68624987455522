import { Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteIconButton } from '../Styled';
import { AttachProps } from './attachProps';
import { FileOutlined } from '@ant-design/icons';
import styles from './Attach.module.scss';
import { openInNewWindow } from '@/utils';
import { AttachmentDto } from '@/core';
import { useCallback } from 'react';

const useOpenFileInNewWindow = (props: AttachProps) => {
  const { blobs } = props;

  return useCallback(
    (item: AttachmentDto) => {
      const blob = blobs?.find((x) => x.id === item.id);
      const url = blob?.blobURL;
      url && openInNewWindow(url);
    },
    [blobs],
  );
};

export function AttachList(props: AttachProps) {
  const {
    remove,
    readonly,
    open,
    value,
    noDeleteConfirm,
    showActions,
    openOnClick,
  } = props;
  const { items, fetchPending } = value;
  const { t } = useTranslation();

  const openDocument = useOpenFileInNewWindow(props);

  return (
    <ul className={styles.list}>
      {items.map((item) => (
        <li key={item.id} title={item.fileName}>
          <span className={styles.title}>
            <Button
              icon={<FileOutlined />}
              disabled={fetchPending}
              type="link"
              onClick={() => openOnClick && open(item.id)}
              style={{ cursor: openOnClick ? 'pointer' : 'default' }}
            >
              <span className={styles.fileName}>{item.fileName}</span>
            </Button>
          </span>

          {showActions && (
            <Space>
              <DeleteIconButton
                disabled={readonly || fetchPending}
                className={styles.delete}
                onClick={() => remove({ id: item.id })}
                entityName={t('attach.entityName')}
                confirm={!noDeleteConfirm}
              />
              <Button
                type="link"
                onClick={() => open(item.id)}
                className={styles.link}
              >
                {t('attach.download')}
              </Button>

              <Button
                type="link"
                onClick={() => openDocument(item)}
                className={styles.link}
              >
                {t('attach.openInNewTab')}
              </Button>
            </Space>
          )}
        </li>
      ))}
    </ul>
  );
}
