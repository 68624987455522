const COLORS = {
  'lighten-1': [
    '#e57373',
    '#f06292',
    '#ba68c8',
    '#9575cd',
    '#7986cb',
    '#64b5f6',
    '#4fc3f7',
    '#4dd0e1',
    '#4db6ac',
    '#66bb6a',
    '#9ccc65',
    '#d4e157',
    '#ffee58',
    '#ffca28',
    '#ffa726',
    '#ff7043',
    '#8d6e63',
    '#bdbdbd',
    '#78909c',
  ],
};

function checksumOfString(value: string) {
  let sum = 0;
  for (let i = 0; i < value.length; i++) {
    sum += value.charCodeAt(i);
  }

  return sum;
}

function randomChecksum() {
  return Math.floor(Math.random() * 100000000);
}

export const color = {
  random: (type: keyof typeof COLORS, seed?: string) => {
    const checksum = seed ? checksumOfString(seed) : randomChecksum();

    return COLORS[type][checksum % COLORS[type].length];
  },
};
