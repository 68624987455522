import {
  ImageMarkerBox,
  ImageMarkerType,
  ImageMarkerValue,
  notify,
} from '@/components';
import { CommunityPermissions } from '@/core';
import {
  PersonalizationItemState,
  RtkqSpin,
  SpecialRequestState,
  useGetPersonalizationItemsQuery,
  useGetProjectHomeInfoQuery,
  useGetSpecialRequestsQuery,
  useUpdateGeoPersonalizationItemsPositionMutation,
} from '@/redux';
import { array } from '@/utils';
import { useFlippoBlobUrl } from '@/utils/flippo';
import { LoadingOutlined } from '@ant-design/icons';
import { Alert, Space } from 'antd';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Assert } from '../Communities.Common';
import { FloorplanPersonalizationImagesControl } from './FloorplanPersonalizationImagesControl';
import { useIsFinalized } from './useIsFinalized';

interface Props {
  homeId: string;
  projectId: string;
  onItemEdit?: (item: PersonalizationItemState) => any;
  onSpecialRequestEdit?: (item: SpecialRequestState) => any;
  selectedStepIds?: string[];
  readonly?: boolean;
  onItemDelete?: (id: string) => any;
  onSpecialRequestDelete?: (id: string, stepId: string) => any;
}

interface MarkerValue extends ImageMarkerValue {
  item?: PersonalizationItemState;
  specialRequest?: SpecialRequestState;
}

function useMarkers(props: Props) {
  const { projectId, homeId, selectedStepIds } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'personalization.floorplan.geo',
  });
  const { data: items = array.empty<PersonalizationItemState>() } =
    useGetPersonalizationItemsQuery({
      projectId,
      homeId,
      isGeo: true,
      stepId: selectedStepIds,
      isFloorplan: true,
    });
  const { data: specialRequests = array.empty<SpecialRequestState>() } =
    useGetSpecialRequestsQuery({
      projectId,
      homeId,
      stepId: selectedStepIds,
      isFloorplan: true,
    });

  const [update, { isLoading: isMarkersUpdating }] =
    useUpdateGeoPersonalizationItemsPositionMutation();

  const markers = useMemo(() => {
    const itemsData = items.map((item) => ({
      ...item.position!,
      number: item.number!,
      label: `${item.category.root.name} - ${item.name}`,
      specialRequest: undefined,
      item: item,
      type: 'circle' as ImageMarkerType,
    }));

    const specialRequestsData = specialRequests
      .filter((x) => x.status !== 'Denied')
      .map((item) => ({
        ...item.position!,
        number: item.number!,
        label: t('specialRequestName', {
          name: item.name,
        }),
        specialRequest: item,
        item: undefined,
        type: 'star' as ImageMarkerType,
      }));

    return [...itemsData, ...specialRequestsData].sort(
      (a, b) => a.number - b.number,
    );
  }, [items, specialRequests, t]);

  const onMarkersChange = useCallback(
    (markers: MarkerValue[]) => {
      const items = markers
        .filter((m) => m.item)
        .map(({ item, x, y }) => ({
          id: item!.id,
          x,
          y,
        }));
      const specialRequests = markers
        .filter((m) => m.specialRequest)
        .map(({ specialRequest, x, y }) => ({
          id: specialRequest!.id,
          x,
          y,
        }));
      update({
        items,
        specialRequests,
        homeId,
        projectId,
        stepIds: selectedStepIds!,
        [RtkqSpin]: false,
      })
        .unwrap()
        .then(() =>
          notify.success.t('personalization.floorplan.geo.markersUpdated'),
        );
    },
    [update, projectId, homeId, selectedStepIds],
  );

  return { markers, isMarkersUpdating, onMarkersChange };
}

function useFloorplan(props: Props) {
  const { homeId, projectId } = props;
  const { data: projectHomeInfo } = useGetProjectHomeInfoQuery({
    id: projectId,
    homeId,
  });
  const { url: floorplanImageUrl } = useFlippoBlobUrl(
    projectHomeInfo?.floorplanImageReference,
  );
  return { floorplanId: projectHomeInfo?.floorplanId, floorplanImageUrl };
}

function _FloorplanGeoPersonalizationPanel(props: Props) {
  const {
    onItemEdit,
    onItemDelete,
    onSpecialRequestEdit,
    onSpecialRequestDelete,
    readonly,
    projectId,
    homeId,
  } = props;
  const { markers, onMarkersChange, isMarkersUpdating } = useMarkers(props);
  const { floorplanId, floorplanImageUrl } = useFloorplan(props);
  const { t } = useTranslation(undefined, {
    keyPrefix: 'personalization.floorplan.geo',
  });
  const isHomeFinalized = useIsFinalized(homeId, projectId);

  if (!floorplanId) {
    return <Alert type="info" description={t('noFloorplan')} />;
  }

  return (
    <ImageMarkerBox
      status={
        isMarkersUpdating && (
          <Space>
            <LoadingOutlined /> {t('saving')}
          </Space>
        )
      }
      markers={markers}
      onMarkersChange={onMarkersChange}
      src={floorplanImageUrl}
      onEdit={
        onItemEdit && onSpecialRequestEdit
          ? (marker) =>
              marker.item
                ? onItemEdit(marker.item)
                : onSpecialRequestEdit(marker.specialRequest)
          : undefined
      }
      onDelete={
        onItemDelete && onSpecialRequestDelete
          ? (marker) =>
              marker.item
                ? onItemDelete(marker.item.id)
                : onSpecialRequestDelete(
                    marker.specialRequest.id,
                    marker.specialRequest.step.id,
                  )
          : undefined
      }
      readonly={readonly}
      actions={
        <Assert permission={CommunityPermissions.Personalization.Manage} active>
          <>
            {!isHomeFinalized && (
              <FloorplanPersonalizationImagesControl
                projectId={projectId}
                homeId={homeId}
                className="pb-2"
              />
            )}
          </>
        </Assert>
      }
    />
  );
}

export function FloorplanGeoPersonalizationPanel(props: Props) {
  return (
    <_FloorplanGeoPersonalizationPanel
      {...props}
      key={props.homeId + props.projectId}
    />
  );
}
