import { Table, TableColumnType } from '@/components';
import { Space, Tag, Typography } from 'antd';
import { useMemo } from 'react';
import { PersonalizationTableRowValues } from './PersonalizationDetails';
import styles from './PersonalizationSummary.module.scss';
import { PersonalizationSummarySubTotalInfo } from './PersonalizationSummarySubtotalInfo';
import { SpecialRequestStatus } from '@/core';

interface Props {
  className?: string;
  title?: string;
  subTitle?: string;
  name: string;
  dataSource: PersonalizationTableRowValues[];
  columns: TableColumnType<PersonalizationTableRowValues>[];
  loading?: boolean;
  subtotalTitle?: string;
  showBaseCost?: boolean;
  showResidentPrice?: boolean;
}

const Text = Typography.Text;
const Title = Typography.Title;

export const useGetPersonalizationItemsSubtotalAmount = (
  data: PersonalizationTableRowValues[],
  by: (item: PersonalizationTableRowValues) => number | undefined,
  show?: boolean,
  checkSpecialRequests?: boolean,
): { amount?: number; isFinalAmount?: boolean } => {
  return useMemo(() => {
    if (!show) return {};
    const filteredData = checkSpecialRequests
      ? data.filter(
          (x) =>
            !x.specialRequest ||
            x.specialRequest.status !== SpecialRequestStatus.Denied,
        )
      : data;
    const isFinalAmount = !filteredData.some(
      (x) =>
        x.specialRequest &&
        x.specialRequest.status !== SpecialRequestStatus.ConstructionApproved &&
        x.specialRequest.status !== SpecialRequestStatus.Denied,
    );

    return {
      amount: filteredData
        ? filteredData.reduce((acc, x) => acc + (by(x) ?? 0), 0)
        : filteredData,
      isFinalAmount,
    };
  }, [data, by, show, checkSpecialRequests]);
};

export function PersonalizationItemList(props: Props) {
  const {
    className,
    dataSource,
    columns,
    title,
    subTitle,
    name,
    subtotalTitle,
    loading,
    showBaseCost,
    showResidentPrice,
  } = props;

  const { amount: subtotalResidentPriceAmount } =
    useGetPersonalizationItemsSubtotalAmount(
      dataSource,
      (x) => x.totalResidentPrice,
      showResidentPrice,
    );

  const { amount: subtotalBaseCostAmount } =
    useGetPersonalizationItemsSubtotalAmount(
      dataSource,
      (x) => x.totalBaseCost,
      showBaseCost,
    );

  return (
    <Space direction="vertical" size="large" className={className}>
      {title && (
        <Title level={4} className={styles.title}>
          {title}
        </Title>
      )}
      {subTitle && (
        <div className={styles.subtitle}>
          <Tag color="blue">
            <Text>{subTitle}</Text>
          </Tag>
        </div>
      )}
      <Table<PersonalizationTableRowValues>
        name={name}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        loading={loading}
        hideDefaultActions
      />
      {subtotalTitle && (
        <PersonalizationSummarySubTotalInfo
          title={subtotalTitle}
          baseCostSubtotal={subtotalBaseCostAmount}
          residentPriceSubtotal={subtotalResidentPriceAmount}
        />
      )}
    </Space>
  );
}
