import { useState } from 'react';
import { Button } from 'antd';
import { CommunityDetailsModal } from '@/views';
import { useTranslation } from 'react-i18next';
import {
  AddCommunityArgs,
  useAddCommunityMutation,
  useAddSubscriptionMutation,
} from '@/redux';
import { useNavigate } from 'react-router-dom';
import { notify } from '@/components';
import { SubscriptionDetailsModal } from '@/views/Subscriptions.Details/SubscriptionDetailsModal';
import { SubscriptionFormValue } from '@/views/Subscriptions.Details/SubscriptionDetailsForm';
import { SubscriptionDetailsDto } from '@/core';

interface Props {
  className?: string;
  clientId?: string;
}

const communityInitialValue: AddCommunityArgs = {
  name: null!,
  description: null!,
  address: {
    street: null!,
    city: null!,
    state: null!,
    zipCode: null!,
  },
  clientId: null!,
};

const subscriptionInitialValue: SubscriptionDetailsDto = {
  communityId: null!,
  communityName: null!,
  clientId: null!,
  clientName: '',
  number: '',
  pmLiteEnabled: false,
  renovationProjectsEnabled: false,
  newConstructionProjectsEnabled: false,
  renovationFloorplanEnabled: false,
  renovationRoomEnabled: false,
  newConstructionFloorplanEnabled: false,
  newConstructionRoomEnabled: false,
};

const useSubmit = (
  setDisplayCommunityModal: (value: boolean) => any,
  setDisplaySubscriptionModal: (value: boolean) => any,
  setSubscriptionInitialValue: (value: SubscriptionDetailsDto) => any,
) => {
  const [add] = useAddCommunityMutation();

  return (args: AddCommunityArgs) => {
    add(args)
      .unwrap()
      .then((id) => {
        notify.success.t('communities.added');
        setSubscriptionInitialValue({
          ...subscriptionInitialValue,
          communityId: id,
          communityName: args.name,
          clientId: args.clientId,
        });
      })
      .then(() => setDisplayCommunityModal(false))
      .then(() => setDisplaySubscriptionModal(true));
  };
};

const useHandleSaveSubscription = () => {
  const [add] = useAddSubscriptionMutation();
  const push = useNavigate();

  return (value: SubscriptionFormValue) => {
    add(value)
      .unwrap()
      .then((communityId) => {
        notify.success.t('subscriptions.added');
        push(`/communities/${communityId}`);
      });
  };
};

export function AddCommunityButton(props: Props) {
  const [openModal, setOpenModal] = useState(false);
  const [openSubscriptionsModal, setOpenSubscriptionsModal] = useState(false);
  const [subscriptionInitialValue, setSubscriptionInitialValue] =
    useState<SubscriptionDetailsDto>();
  const handleSaveSubscription = useHandleSaveSubscription();
  const submit = useSubmit(
    setOpenModal,
    setOpenSubscriptionsModal,
    setSubscriptionInitialValue,
  );
  const { t } = useTranslation();
  const { className, clientId } = props;

  return (
    <div className={className}>
      <Button type="primary" onClick={() => setOpenModal(true)}>
        {t('communities.details.addTitle')}
      </Button>
      {openModal && (
        <CommunityDetailsModal
          title={t('communities.details.addTitle')}
          clientSelectionDisabled={!!clientId}
          buttonSaveName={t('communities.details.addButton')}
          onSubmit={submit}
          value={{ ...communityInitialValue, clientId: clientId ?? null! }}
          onClose={() => setOpenModal(false)}
        />
      )}
      {openSubscriptionsModal && (
        <SubscriptionDetailsModal
          disabledCancel
          onSave={handleSaveSubscription}
          disabledCommunity
          disabledClient
          value={subscriptionInitialValue}
          saveBtnText={t('subscriptions.details.addBtnText')}
          title={t('subscriptions.details.addTitle')}
          type="subscription"
        />
      )}
    </div>
  );
}
