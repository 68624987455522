import { Icon, Page } from '@/components';
import { useParams } from 'react-router-dom';
import { DevelopScopePanel } from '@/views';
import { useTranslation } from 'react-i18next';
import styles from './ProjectDevelopScopePage.module.scss';

export function ProjectDevelopScopePage() {
  const { t } = useTranslation();
  const { id } = useParams<'id'>();

  return (
    <Page.Card
      title={t('developScope.title')}
      icon={<Icon type="edit" />}
      color="#EFAC4E"
      className={styles.card}
    >
      {id && <DevelopScopePanel projectId={id!} />}
    </Page.Card>
  );
}
