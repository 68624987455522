import qs from 'qs';
import { Http, Paging } from '../http';
import {
  DeleteItemPriceDto,
  DeletePricingMarkupDto,
  HomePriceMatrixDto,
  HomePriceMatrixFilterParams,
  ItemPriceGroupDto,
  PriceMatrixDto,
  PriceMatrixFilterParams,
  PricingSettingsMatrixDto,
  SaveHomeItemPriceDto,
  SaveItemPriceDto,
  SavePricingMarkupDto,
  SpecialRequestPriceMatrixDto,
  SpecialRequestPriceMatrixFilterParams,
  UpdateItemMenuItemPricingInfoDto,
  UpdatePricingMarkupDefaultsDto,
  UpdateSpecialRequestPriceMatrixRowDto,
  UpdateSpecialRequestPriceMatrixRowResultDto,
} from './pricingTypes';

class PricingHttp extends Http {
  public async getMatrix(
    communityId: string,
    menuId: string,
    paging?: Paging,
    filters?: PriceMatrixFilterParams,
  ): Promise<PriceMatrixDto> {
    const query = qs.stringify({
      communityId,
      menuId,
      ...paging,
      ...filters,
    });
    const uri = `/api/pricing/matrix?${query}`;
    const response = await this._axios.get<PriceMatrixDto>(uri);
    return response.data;
  }

  public async saveItemPrice(
    request: SaveItemPriceDto,
  ): Promise<ItemPriceGroupDto> {
    const response = await this._axios.post('/api/pricing/item', request);
    return response.data;
  }

  public async deleteItemPrice(
    request: DeleteItemPriceDto,
  ): Promise<ItemPriceGroupDto> {
    const response = await this._axios.delete('/api/pricing/item', {
      data: request,
    });
    return response.data;
  }

  public async saveMarkup(request: SavePricingMarkupDto) {
    await this._axios.post('/api/pricing/markup', request);
  }

  public async deleteMarkup(request: DeletePricingMarkupDto) {
    await this._axios.delete('/api/pricing/markup', { data: request });
  }

  public async updateMarkupDefaults(request: UpdatePricingMarkupDefaultsDto) {
    await this._axios.put('/api/pricing/markup/defaults', request);
  }

  public async getSettingsMatrix(
    communityId: string,
    menuId?: string,
  ): Promise<PricingSettingsMatrixDto> {
    const query = qs.stringify({ communityId, menuId });
    const uri = `/api/pricing/settings-matrix?${query}`;
    const response = await this._axios.get<PricingSettingsMatrixDto>(uri);
    return response.data;
  }

  public async getSpecialRequestMatrix(
    paging: Paging,
    communityId: string,
    projectIds?: string[],
    filters?: SpecialRequestPriceMatrixFilterParams,
  ): Promise<SpecialRequestPriceMatrixDto> {
    const query = qs.stringify({
      communityId,
      projectIds,
      ...paging,
      ...filters,
    });
    const uri = `/api/pricing/special-request-matrix?${query}`;
    const response = await this._axios.get(uri);
    return response.data;
  }

  public async updateSpecialRequestPriceMatrixRow(
    request: UpdateSpecialRequestPriceMatrixRowDto,
  ): Promise<UpdateSpecialRequestPriceMatrixRowResultDto> {
    const uri = '/api/pricing/special-request-matrix-row';
    const response = await this._axios.put(uri, request);
    return response.data;
  }

  public async updateItemPricingInfo(
    request: UpdateItemMenuItemPricingInfoDto,
  ): Promise<ItemPriceGroupDto> {
    const uri = `/api/pricing/item/${request.id}/pricing-info`;
    const { data } = await this._axios.put(uri, request);
    return data;
  }

  public async copyPricing(request: {
    fromMenuId: string;
    toMenuId: string;
  }): Promise<void> {
    await this._axios.put(`/api/pricing/copy?${qs.stringify(request)}`);
  }

  public async getHomeMatrix(
    homeIds: string[],
    paging: Paging,
    filters?: HomePriceMatrixFilterParams,
  ): Promise<HomePriceMatrixDto> {
    const query = qs.stringify({
      homeIds,
      ...paging,
      ...filters,
    });
    const uri = `/api/pricing/home-matrix?${query}`;
    const response = await this._axios.get<HomePriceMatrixDto>(uri);
    return response.data;
  }

  public async saveHomeItemPrice(request: SaveHomeItemPriceDto) {
    const response = await this._axios.post('/api/pricing/home-item', request);
    return response.data;
  }
}

export const pricingHttp = new PricingHttp();
