export const browserDownload = (fileName: string, blob: Blob) => {
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);

  link.click();

  setTimeout(() => {
    link!.parentNode!.removeChild(link);
    URL.revokeObjectURL(url);
  }, 0);
};

export const openInNewWindow = (url: string) => {
  window.open(url, '_blank');
};
