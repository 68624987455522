import { HomeDto, HomeFilterParams } from '@/core';
import { HomeState, useGetHomesListQuery } from '@/redux';
import { Alert, Button, Col, Modal, Row } from 'antd';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHomesColumns } from '../Homes.List/useHomesColumns';
import {
  useDefaultTablePaging,
  useFilters,
  useSorting,
} from '@/components/Collections.Paging';

import { Table } from '@/components';

interface Props {
  communityId: string;
  projectId: string | null | undefined;
  homeIds: string[];
  onCancel: () => void;
  onSave: (selected: string[]) => void;
}

export function ProjectHomesModal(props: Props) {
  const {
    onCancel,
    onSave: onSaveProp,
    communityId,
    projectId,
    homeIds,
  } = props;

  const { setTotal, paging, params } = useDefaultTablePaging();
  const {
    filters,
    onFiltersChange,
    resetFilters,
    stringFilterOptions,
    setStringFilterOptions,
  } = useFilters<HomeFilterParams>([
    'number',
    'homeNumber',
    'locationLevel1Name',
    'locationLevel2Name',
    'locationLevel3Name',
    'constructionNumber',
    'floorplanMarketingName',
    'projectName',
    'menu',
    'residentFilter',
  ]);
  const { sorting, onSortingChange } = useSorting<HomeState>({
    sortBy: 'homeNumber',
    sortOrder: 'ascend',
  });

  const { t } = useTranslation();

  const { data, isFetching } = useGetHomesListQuery({
    communityId,
    projectId: projectId ?? undefined,
    paging: params,
    filterForAddingAvailability: true,
    filters,
    sorting,
  });

  const homes = useMemo(() => {
    if (data) {
      setTotal(data.total);
      setStringFilterOptions(data.stringFilterOptions);
    }
    return data?.items;
  }, [data, setStringFilterOptions, setTotal]);

  const [selected, setSelected] = useState<string[]>([]);
  const prevHomeIds = useRef<string[]>([]);

  const columns = useHomesColumns(
    {
      communityId,
      rowActions: false,
      noProject: true,
    },
    homes,
    stringFilterOptions,
  );

  useEffect(() => {
    if (isEqual(prevHomeIds.current, homeIds)) {
      return;
    }

    setSelected(homeIds);
    // eslint-disable-next-line
  }, [homeIds]);

  const onSave = useCallback(
    () => onSaveProp(selected),
    [selected, onSaveProp],
  );

  return (
    <Modal
      title={t('projects.details.selectHomesTitle')}
      width={1000}
      visible
      onCancel={onCancel}
      footer={[
        <Row justify="space-between" align="middle" key="actions">
          <Col>
            {t('projects.details.selectedHomes', { count: selected.length })}
          </Col>
          <Col>
            <Button onClick={onCancel}>{t('projects.details.cancel')}</Button>
            <Button type="primary" onClick={onSave}>
              {t('projects.details.editBtnText')}
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <Alert
        className="mb-2"
        type="info"
        description={t('projects.homeList.editTip')}
      />
      <Table<HomeState>
        name="projectHomes"
        loading={isFetching}
        columns={columns}
        dataSource={homes}
        rowKey={(row: HomeDto) => row.id}
        scroll={{ x: 1600 }}
        rowSelection={{
          type: 'checkbox',
          onChange: (_, rows) =>
            setSelected([
              ...getSelectedHomes(selected, homes),
              ...rows.map((x) => x.id),
            ]),
          selectedRowKeys: selected,
        }}
        sticky={{ offsetHeader: 56 }}
        pagination={paging}
        onChange={(_, filters, sorting) => {
          onFiltersChange(filters);
          onSortingChange(sorting);
        }}
        onFiltersReset={resetFilters}
      />
    </Modal>
  );
}

function getSelectedHomes(
  selected: string[],
  selectedAndUnassignedHomes?: HomeDto[],
) {
  return selected.filter(
    (y) => !(selectedAndUnassignedHomes || []).map((x) => x.id).includes(y),
  );
}
