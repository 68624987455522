import { useTranslation } from 'react-i18next';
import { Tabs } from '@/components';
import { enumObject } from '@/utils';
import { SubscriptionListTabPanel } from './SubscriptionListTabPanel';

const TAB_KEYS = ['subscription', 'special-request-subscription'] as const;

export const SubscriptionTabContentKeys = enumObject(
  'SubscriptionTabContentKeys',
  TAB_KEYS,
);

const { TabPane } = Tabs;

export const SubscriptionTabPanel = () => {
  const { t } = useTranslation();

  return (
    <Tabs tabKeys={SubscriptionTabContentKeys} destroyInactiveTabPane>
      <TabPane
        tab={t('subscriptions.title')}
        key={SubscriptionTabContentKeys['subscription']}
      >
        <SubscriptionListTabPanel showCreateButton type="subscription" />
      </TabPane>
      <TabPane
        tab={t('subscriptions.specialRequests.title')}
        key={SubscriptionTabContentKeys['special-request-subscription']}
      >
        <SubscriptionListTabPanel type="special-request" />
      </TabPane>
    </Tabs>
  );
};
