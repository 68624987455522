import { useField } from 'formik';
import { FormsGroup } from './FormsGroup';
import { useFormLabel } from './FormsI18nContext';
import React from 'react';
import { InputNumber, InputNumberProps } from 'antd';
import { useCallback } from 'react';
import classnames from 'classnames';

export type FormsNumberProps = {
  name: string;
  label?: React.ReactNode | false;
  required?: boolean;
  className?: string;
  placeholder?: string;
  prefix?: React.ReactNode;
  min?: number;
  max?: number;
  precision?: number;
  onBlur?: InputNumberProps['onBlur'];
};

export const defaultInputNumberProps: InputNumberProps<number | string> = {
  formatter: (value?: number | string) =>
    getThousandSeparatorExp(value as number),
  parser: (value?: number | string) => parseFormattedValue(value as string),
  className: 'w-100',
};

function getThousandSeparatorExp(value?: number): string {
  return `${value}`.includes('.')
    ? `${value}`
    : `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function parseFormattedValue(value?: string): number | string {
  return value!.replace(/\$\s?|(,*)/g, '');
}

const MAX_DECIMAL_PART_LENGTH = 15;

export const FormsNumber = ({
  name,
  label: labelOverride,
  required,
  className,
  placeholder,
  prefix,
  min,
  max,
  precision = 2,
  onBlur,
}: FormsNumberProps) => {
  const [field, , { setValue }] = useField(name);
  const label = useFormLabel(name, labelOverride);

  const trimValue = (val: any) => {
    if (typeof val === 'number') {
      const [integerPart, decimalPart] = val.toString().split('.');

      if (decimalPart && decimalPart.length > MAX_DECIMAL_PART_LENGTH) {
        const trimmedDecimal = decimalPart.slice(0, MAX_DECIMAL_PART_LENGTH);
        return parseFloat(`${integerPart}.${trimmedDecimal}`);
      }
    }
    return val;
  };

  const handleChange = useCallback(
    (value: any) => {
      const newValue = value ? trimValue(value) : 0;
      setValue(newValue, true);
    },
    [setValue],
  );

  const { onBlur: fieldOnBlur } = field;

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      fieldOnBlur(e);
      onBlur && onBlur(e);
    },
    [onBlur, fieldOnBlur],
  );

  return (
    <FormsGroup label={label} name={name} required={required}>
      <InputNumber
        {...field}
        {...defaultInputNumberProps}
        onBlur={handleBlur}
        prefix={prefix}
        value={field.value ?? ''}
        onChange={handleChange}
        className={classnames(defaultInputNumberProps?.className, className)}
        placeholder={placeholder}
        min={min}
        max={max}
        precision={precision}
      />
    </FormsGroup>
  );
};
