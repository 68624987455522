import { EditIconButton, LookupOption, notify } from '@/components';
import { useMemo, useState } from 'react';
import {
  SpecialRequestState,
  UpdateSpecialRequestArgs,
  useUpdateSpecialRequestMutation,
} from '@/redux';
import { useTranslation } from 'react-i18next';
import { SpecialRequestDetailsModal } from './SpecialRequestDetailsModal';
import { SpecialRequestCommentsModal } from './SpecialRequestCommentsModal';
import { useCommunityContext } from '@/views/Communities.Common';
import { CommunityPermissions } from '@/core';

interface Props {
  stepId: string;
  projectId: string;
  homeId: string;
  value: SpecialRequestState;
  disabledCategory?: boolean;
  isTableAction?: boolean;
}

function useFormValue(value: SpecialRequestState | undefined) {
  return useMemo<UpdateSpecialRequestArgs | undefined>(
    () =>
      value
        ? {
            id: value.id,
            stepId: value.step.id,
            homeId: value.homeId,
            projectId: value.projectId,
            categoryId: value.category.category.id,
            name: value.name,
            status: value.status,
            description: value.description,
            isFloorplan: value.isFloorplan,
            roomId: value.room?.id,
            fee: value.fee,
          }
        : undefined,
    [value],
  );
}

export const EditSpecialRequestButton = (props: Props) => {
  const { value, disabledCategory, isTableAction } = props;
  const { modal, open } = useEditSpecialRequestAction(disabledCategory);

  return (
    <>
      <EditIconButton
        className={isTableAction ? 'table-action' : undefined}
        onClick={() => open(value)}
      />
      {modal}
    </>
  );
};

const useSubmit = (onClose: () => any) => {
  const [add] = useUpdateSpecialRequestMutation();

  return (args: UpdateSpecialRequestArgs) =>
    add(args)
      .unwrap()
      .then(() => {
        notify.success.t('specialRequests.saved');
        onClose();
      });
};

export function useEditSpecialRequestAction(disabledCategory?: boolean) {
  const [item, setItem] = useState<SpecialRequestState>();
  const formValue = useFormValue(item);
  const { t } = useTranslation();
  const submit = useSubmit(() => setItem(undefined));

  const preloadValue: LookupOption = useMemo(
    () => ({
      label: item?.category.category.name ?? '',
      value: item?.category.category.id ?? '',
    }),
    [item?.category],
  );

  const { assert } = useCommunityContext();

  const isEditingEnabled = assert(
    CommunityPermissions.Personalization.Manage,
    true,
  );

  return {
    open: setItem,
    modal:
      item &&
      (!isEditingEnabled ? (
        <SpecialRequestCommentsModal
          title={t('specialRequests.addComments')}
          onClose={() => setItem(undefined)}
          value={formValue}
        />
      ) : (
        <SpecialRequestDetailsModal
          stepId={item.step.id}
          projectId={item.projectId}
          homeId={item.homeId}
          onClose={() => setItem(undefined)}
          onSubmit={submit}
          title={t('specialRequests.editTitle')}
          value={formValue}
          isEdit
          isFloorplan={item.isFloorplan}
          preloadValue={preloadValue}
          disabledCategory={disabledCategory}
          position={item.position}
          number={item.number}
        />
      )),
  };
}
