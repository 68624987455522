import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ClientFilterParams,
  ClientType,
  Paging,
  StringFilterOptions,
  UsaStates,
} from '@/core';
import {
  useGetClientsListQuery,
  useUserContextSelector,
  ClientState,
} from '@/redux';
import {
  ContextMenu,
  DefaultNumberSorting,
  EditIconButton,
  EnumValue,
  Table,
  TableColumnType,
  useDefaultTablePaging,
  useFilters,
  useSorting,
} from '@/components';
import { useDeleteClientAction, useEditClientAction } from '@/views';
import { NavLink } from 'react-router-dom';
import { useFiltersFactory } from '@/utils';
import { RtkqSpin } from '@/redux/rtkq';

function useColumns(
  openEdit: (id: string) => any,
  confirmDelete: (id: string) => any,
  paging: Paging,
  stringFilterOptions?: StringFilterOptions,
) {
  const hasRole = useUserContextSelector((x) => x.hasRole);

  const { t } = useTranslation();
  const { data } = useGetClientsListQuery({
    paging,
    [RtkqSpin]: false,
  });

  const clients = useMemo(() => {
    return data?.items;
  }, [data?.items]);

  const filters = useFiltersFactory(clients, stringFilterOptions);
  const canModify = hasRole('BFP_ADMIN');

  return useMemo<TableColumnType<ClientState>[]>(() => {
    const columns: Array<TableColumnType<ClientState> | false> = [
      {
        title: t('clients.number'),
        key: 'number',
        defaultSortOrder: 'descend',
        ...filters.select((x) => x.number, 'number'),
        sorter: (a, b) => a.number.localeCompare(b.number),
        render: (_, item) => item.number,
      },
      {
        title: t('clients.name'),
        key: 'name',
        ...filters.select((x) => x.name, 'name'),
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (_, { id, name }) => (
          <NavLink to={`/clients/${id}`}>{name}</NavLink>
        ),
      },
      {
        title: t('clients.type'),
        key: 'type',
        ...filters.enumSelect((x) => x.type, ClientType),
        sorter: (a, b) => a.type.localeCompare(b.type),
        render: (_, item) => <EnumValue type={ClientType} value={item.type} />,
      },
      {
        title: t('clients.address.city'),
        key: 'city',
        ...filters.plainText((x) => x.address.city),
        sorter: (a, b) => a.address.city.localeCompare(b.address.city),
        render: (_, item) => item.address.city,
      },
      {
        title: t('clients.address.state'),
        width: '10%',
        key: 'state',
        ...filters.enumSelect((x) => x.address.state, UsaStates),
        sorter: (a, b) => a.address.state.localeCompare(b.address.state),
        render: (_, item) => (
          <EnumValue type={UsaStates} value={item.address.state} />
        ),
      },
      {
        title: t('clients.communityCount'),
        key: 'communityCount',
        width: '10%',
        sorter: (a, b) => a.communityCount! - b.communityCount!,
        ...filters.numberRange((x) => x.communityCount),
        render: (_, item) => item.communityCount,
      },
      {
        title: t('clients.userCount'),
        key: 'userCount',
        sorter: (a, b) => a.userCount! - b.userCount!,
        ...filters.numberRange((x) => x.userCount),
        render: (_, item) => item.userCount,
      },
      canModify && {
        title: t('clients.actions'),
        key: 'actions',
        render: (_, { id, userCount, communityCount }) =>
          userCount! > 0 || communityCount! > 0 ? (
            <EditIconButton
              type="link"
              onClick={() => openEdit(id)}
              className="table-action"
            />
          ) : (
            <ContextMenu
              items={[
                {
                  onClick: () => openEdit(id),
                  label: t('clients.action.edit'),
                },
                {
                  onClick: () => confirmDelete(id),
                  label: t('clients.action.delete'),
                },
              ]}
            />
          ),
      },
    ];
    return columns.filter((x) => !!x) as TableColumnType<ClientState>[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, hasRole, openEdit]);
}

export function ClientsListPanel() {
  const { openEdit, editModal } = useEditClientAction();
  const { deleteAction } = useDeleteClientAction();
  const { setTotal, paging, params } = useDefaultTablePaging();
  const {
    filters,
    onFiltersChange,
    resetFilters,
    stringFilterOptions,
    setStringFilterOptions,
  } = useFilters<ClientFilterParams>(['name', 'number']);
  const { sorting, onSortingChange } =
    useSorting<ClientState>(DefaultNumberSorting);

  const { data, isFetching } = useGetClientsListQuery({
    paging: params,
    filters,
    sorting,
  });

  const clients = useMemo(() => {
    if (data) {
      setTotal(data.total);
      setStringFilterOptions(data.stringFilterOptions);
    }
    return data?.items;
  }, [data, setStringFilterOptions, setTotal]);

  const columns = useColumns(
    openEdit,
    deleteAction,
    params,
    stringFilterOptions,
  );

  return (
    <div>
      <Table<ClientState>
        name="Clients"
        loading={isFetching}
        columns={columns}
        dataSource={clients}
        rowKey={(row) => row.id}
        pagination={paging}
        onChange={(_, filters, sorting) => {
          onFiltersChange(filters);
          onSortingChange(sorting);
        }}
        onFiltersReset={resetFilters}
      />
      {editModal}
    </div>
  );
}
