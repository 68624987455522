import {
  LookupOption,
  LookupSource,
  createEntireLoadLookupSource,
} from '@/components';
import { DEFAULT_PAGING, ResidentLookupDto } from '@/core';
import { useLazyGetHomesListQuery } from '@/redux';
import { RtkqSpin } from '@/redux/rtkq';
import { Tooltip, Typography } from 'antd';
import { useMemo } from 'react';

const { Text } = Typography;

export function mapHomeLookup(value: {
  id: string;
  constructionNumber: string;
  homeNumber: string;
  number: string;
  residents?: ResidentLookupDto[];
}): LookupOption {
  const { constructionNumber, homeNumber, number, residents } = value;

  const searchValue = [
    constructionNumber,
    homeNumber,
    number,
    residents?.map((x) => x.name),
  ].join(' ');

  const text = `${homeNumber} ${
    residents
      ? residents?.length > 0
        ? `  - ${residents.map((x) => x.name).join(', ')}`
        : ''
      : ''
  }`;

  return {
    _search: searchValue,
    value: value.id,
    label: (
      <span>
        <Tooltip placement="left" title={text}>
          <div>
            <Text ellipsis={{ tooltip: false }}>{text}</Text>
          </div>
          <div>
            <Text type="secondary">{constructionNumber}</Text>
          </div>
        </Tooltip>
      </span>
    ),
    fullText: text,
  };
}

export function useHomeLookupSource(
  communityId?: string | string[],
  projectId?: string | string[],
  showResidentNames?: boolean,
): LookupSource {
  const [fetchList] = useLazyGetHomesListQuery();

  return useMemo(() => {
    const entireSource = createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList(
          { communityId, projectId, paging: DEFAULT_PAGING, [RtkqSpin]: false },
          true,
        )
          .unwrap()
          .then((values) => {
            const home = values.items.find((x) => x.id === id)!;

            return mapHomeLookup({
              ...home,
              residents: showResidentNames ? home.residents : undefined,
            });
          }),
      load: () =>
        fetchList(
          { communityId, projectId, paging: DEFAULT_PAGING, [RtkqSpin]: false },
          true,
        )
          .unwrap()
          .then((values) => {
            return values.items.map((x) =>
              mapHomeLookup({
                ...x,
                residents: showResidentNames ? x.residents : undefined,
              }),
            );
          }),
    });

    return { ...entireSource, searchField: '_search' };
  }, [fetchList, communityId, projectId, showResidentNames]);
}
