import { Attach } from '@/components';
import { CommunityPermissions, useAppClippo } from '@/core';
import { useClippoBlobs } from '@/utils';
import { useCommunityContext } from '@/views';
import { Col, Row } from 'antd';

interface Props {
  id: string;
}

export const HomeAttachments = (props: Props) => {
  const { id } = props;
  const clippoProps = useAppClippo({
    defer: false,
    ownerId: id,
    ownerType: 'Home',
  });

  const clippoBlobs = useClippoBlobs(clippoProps);

  const { active, hasPermission } = useCommunityContext();
  const readonly =
    !active || !hasPermission(CommunityPermissions.HomeDocuments.Manage);

  return (
    <Row>
      <Col>
        <Attach
          className="mt-3"
          {...clippoProps}
          readonly={readonly}
          multiple
          blobs={clippoBlobs.blobs}
          showActions
          openOnClick={false}
        />
      </Col>
    </Row>
  );
};
