import { AddButton, notify } from '@/components';
import { useAddSubscriptionMutation } from '@/redux';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionFormValue } from './SubscriptionDetailsForm';
import { SubscriptionDetailsModal } from './SubscriptionDetailsModal';

function useHandleSave(setDisplay: (value: boolean) => any) {
  const [add] = useAddSubscriptionMutation();

  return useCallback(
    (value: SubscriptionFormValue) => {
      add(value)
        .unwrap()
        .then(() => notify.success.t('subscriptions.added'))
        .then(() => setDisplay(false));
    },
    [add, setDisplay],
  );
}

export function AddSubscriptionButton() {
  const { t } = useTranslation();
  const [display, setDisplay] = useState(false);
  const handleSave = useHandleSave(setDisplay);

  return (
    <>
      <AddButton onClick={() => setDisplay(true)}>
        {t('subscriptions.addBtnText')}
      </AddButton>
      {display && (
        <SubscriptionDetailsModal
          onCancel={() => setDisplay(false)}
          onSave={handleSave}
          saveBtnText={t('subscriptions.details.addBtnText')}
          title={t('subscriptions.details.addTitle')}
          type="subscription"
        />
      )}
    </>
  );
}
