import { Icon, Page } from '@/components';
import { CommunityPermissions } from '@/core';
import { useGetHomeDetailsQuery } from '@/redux';
import {
  Assert,
  CommunityDetailsTabKeys,
  HomeDetailsPanel,
  HomeDetailsTabsPanel,
  HomeMenuLink,
  HomeProjectLink,
} from '@/views';
import { HomeFloorplanLink } from '@/views/Homes.Details/HomeFloorplanLink';
import { HomeNavigationPanel } from '@/views/Homes.Details/HomeNavigationPanel';
import { Col, Row, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styles from './HomeDetailsPage.module.scss';

const useId = () => {
  const { id } = useParams<'id'>();
  return id!;
};

function _HomeDetailsPage() {
  const { t } = useTranslation();
  const id = useId();
  const { data } = useGetHomeDetailsQuery({ id });
  const title = data?.homeNumber ?? t('fetchFallbackTitle');
  const titleSecondary =
    data?.residents?.map((x) => x.name).join(', ') ?? t('fetchFallbackTitle');

  return (
    <Page htmlTitle={title}>
      <Page.Paths>
        <Page.Path href="/communities">{t('communities.title')}</Page.Path>
        <Page.Path
          href={`/communities/${data?.communityId}#${CommunityDetailsTabKeys.homes}`}
        >
          {data?.communityName ?? t('fetchFallbackTitle')}
        </Page.Path>
        <Page.Path
          href={`/communities/${data?.communityId}#${CommunityDetailsTabKeys.homes}`}
        >
          {t('homes.title')}
        </Page.Path>
        <Page.Path>{title}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <HomeNavigationPanel id={id} className="mb-3" />

        <Page.Card
          collapsible
          defaultCollapsed
          title={
            <Tooltip title={title} placement="topLeft">
              <div className={styles.title}>{title}</div>
            </Tooltip>
          }
          subTitle={
            <Tooltip title={titleSecondary} placement="right">
              <div className={styles.subtitle}>{titleSecondary}</div>
            </Tooltip>
          }
          actions={
            <Row gutter={[14, 0]}>
              <div className={styles.actions}>
                <Col>
                  <HomeFloorplanLink
                    homeId={id}
                    communityId={data?.communityId}
                    floorplanId={data?.floorplan?.id}
                    floorplanName={data?.floorplan?.marketingName}
                  />
                </Col>
                <Col>
                  <HomeMenuLink
                    homeId={id}
                    projectId={data?.projectId}
                    menu={data?.menu}
                    communityId={data?.communityId}
                  />
                </Col>
                <Col>
                  <HomeProjectLink
                    homeId={id}
                    projectId={data?.projectId}
                    projectName={data?.projectName}
                  />
                </Col>
              </div>
            </Row>
          }
          icon={<Icon type="home" />}
          color="#107CC7"
        >
          <HomeDetailsPanel id={id} />
        </Page.Card>

        <Page.TabCard>
          <HomeDetailsTabsPanel id={id} />
        </Page.TabCard>
      </Page.Body>
    </Page>
  );
}

export const HomeDetailsPage = Assert.Banner(CommunityPermissions.Homes.View)(
  _HomeDetailsPage,
);
