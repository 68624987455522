import { Form } from '@/components';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useBool } from '@/utils';
import { FilePdfOutlined } from '@ant-design/icons';
import { useCallback } from 'react';
import {
  Language,
  personalizationsHttp,
  ReportOrientationType,
  ReportSizeType,
} from '@/core';
import { DownloadPdfFormModal } from './DownloadPdfFormModal';

export function useDownloadPdf(
  projectId: string,
  homeId: string,
  showBaseCost: boolean,
  showResidentPrice: boolean,
  showResidentInfo: boolean,
  orientation: ReportOrientationType,
  language: Language,
  showConstruction: boolean,
  stepId?: string,
) {
  return useCallback(async () => {
    return await personalizationsHttp.downloadPdf(
      projectId,
      homeId,
      showBaseCost,
      showResidentPrice,
      showResidentInfo,
      orientation,
      language,
      showConstruction,
      stepId,
    );
  }, [
    projectId,
    homeId,
    showBaseCost,
    showResidentPrice,
    showResidentInfo,
    orientation,
    language,
    showConstruction,
    stepId,
  ]);
}

export function useDownloadTabloidPdf(
  projectId: string,
  homeId: string,
  language: Language,
  showResidentInfo: boolean,
  stepId?: string,
) {
  return useCallback(async () => {
    return await personalizationsHttp.downloadTabloidPdf(
      projectId,
      homeId,
      language,
      showResidentInfo,
      stepId,
    );
  }, [projectId, homeId, language, showResidentInfo, stepId]);
}

export interface DownloadPdfArgs {
  orientation: ReportOrientationType;
  size: ReportSizeType;
  language: Language;
  showConstruction: boolean;
}

const initialValues: DownloadPdfArgs = {
  orientation: 'Portrait',
  size: 'Letter',
  language: Language.English,
  showConstruction: false,
};

export interface DownloadPdfButtonProps {
  projectId: string;
  homeId: string;
  showBaseCost: boolean;
  showResidentPrice: boolean;
  showResidentInfo: boolean;
  selectedStepId?: string;
}

export function DownloadPdfButton(props: DownloadPdfButtonProps) {
  const { t } = useTranslation();
  const [modalOpen, , toggleModalOpen] = useBool(false);

  return (
    <Form.Formik<DownloadPdfArgs>
      uid="pdf"
      i18n="personalization.downloadPdf"
      initialValues={initialValues}
      onSubmit={(_, helpers) => helpers.resetForm()}
      enableReinitialize
    >
      <Button
        icon={<FilePdfOutlined />}
        type="default"
        onClick={toggleModalOpen}
      >
        {t('downloadPdf')}
      </Button>
      <DownloadPdfFormModal
        {...props}
        open={modalOpen}
        toggleModalOpen={toggleModalOpen}
      />
    </Form.Formik>
  );
}
