import { useField } from 'formik';
import { FormsGroup } from './FormsGroup';
import { useFormLabel } from './FormsI18nContext';
import React, { useCallback, useEffect, useState } from 'react';
import { Input, InputProps } from 'antd';
import { convertToFormattedPhone } from '@/utils';

export type FormsInputProps = {
  name: string;
  label?: React.ReactNode;
  type?: InputProps['type'];
  required?: boolean;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
};

export const FormsPhoneInput = ({
  name,
  type,
  label: labelOverride,
  required,
  disabled,
  className,
  placeholder,
}: FormsInputProps) => {
  const [field, , helpers] = useField(name);
  const label = useFormLabel(name, labelOverride);
  const [formattedValue, setFormattedValue] = useState<string>('');

  useEffect(() => {
    setFormattedValue(convertToFormattedPhone(field.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = convertToFormattedPhone(e.target.value);
      setFormattedValue(value);

      if (value?.length === 0) {
        helpers.setValue(null);
      } else {
        helpers.setValue(value.replace(/\D/g, ''));
      }
    },
    [helpers],
  );

  return (
    <FormsGroup label={label} name={name} required={required}>
      <Input
        addonBefore="+1"
        type={type}
        {...field}
        value={formattedValue}
        onChange={handleChange}
        className={className}
        disabled={disabled}
        placeholder={placeholder}
      />
    </FormsGroup>
  );
};
