import { useState } from 'react';
import styles from './ImageMarkerBox.module.scss';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Button, Space } from 'antd';
import { ArrowsAltOutlined } from '@ant-design/icons';
import { Overlay } from '../Overlay';
import { ImageMarkerView } from './ImageMarkerView';
import { useMarkerBoxDrop } from './useMarkerBoxDrop';
import { useTranslation } from 'react-i18next';
import { ImageMarkerBoxLock } from './ImageMarkerBoxLock';
import { useBool, useClickOutside } from '@/utils';

export interface ImageMarkerValue {
  x: number;
  y: number;
  number: number;
  label: string;
  type: ImageMarkerType;
}

export type ImageMarkerType = 'circle' | 'star';

export interface ImageMarkerBoxProps<T extends ImageMarkerValue> {
  markers: T[];
  onMarkersChange: (values: T[]) => any;
  onEdit?: (value: T) => any;
  onDelete?: (value: T) => any;
  readonly?: boolean;
  src: string | undefined;
  actions?: React.ReactNode;
  status?: React.ReactNode;
}

function NoImage() {
  const { t } = useTranslation();
  return <div className={styles.container}>{t('imageMarkerBox.noImage')}</div>;
}

function _ImageMarkerBox<T extends ImageMarkerValue>(
  props: ImageMarkerBoxProps<T>,
) {
  const {
    src,
    markers,
    status,
    onDelete,
    onEdit,
    readonly: readonlyProp,
    actions,
  } = props;
  const [expanded, , toggleExpanded] = useBool(false);
  const [locked, setLocked] = useState(true);
  const { dropRef } = useMarkerBoxDrop(props);
  const readonly = readonlyProp || locked;
  const { onContainerClick, contentRef } = useClickOutside(toggleExpanded);

  if (!src) {
    return (
      <div className={styles.container}>
        <div className={styles.actions}>
          <Space size="large">{actions}</Space>
        </div>
        <NoImage />
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <div className={styles.actions}>
        <span className={styles.status}>{status}</span>
        {!readonlyProp && (
          <ImageMarkerBoxLock
            locked={locked}
            onChange={setLocked}
            className={styles.lock}
          />
        )}
        <Space size="large">
          {actions}
          <Button
            onClick={toggleExpanded}
            icon={<ArrowsAltOutlined />}
            type="link"
          />
        </Space>
      </div>

      <ImageMarkerView
        imageRef={dropRef}
        markers={markers}
        src={src}
        readonly={readonly}
        onDelete={onDelete}
        onEdit={onEdit}
      />

      <Overlay visible={expanded} actions={['close']} onClose={toggleExpanded}>
        <div className={styles.imageView} onClick={onContainerClick}>
          <ImageMarkerView
            markers={markers}
            src={src}
            className={styles.previewView}
            imageRef={contentRef}
            readonly
          />
        </div>
      </Overlay>
    </div>
  );
}

export function ImageMarkerBox<T extends ImageMarkerValue>(
  props: ImageMarkerBoxProps<T>,
) {
  return (
    <DndProvider backend={HTML5Backend}>
      <_ImageMarkerBox {...props} />
    </DndProvider>
  );
}
