import { Col, Row, Space, Tag, Typography } from 'antd';
import {
  AddPersonalizationItemButton,
  AddSpecialRequestButton,
  Assert,
  PersonalizationList,
  useIsFinalized,
} from '@/views';
import { CommunityPermissions, ProjectStepLookupDto } from '@/core';
import {
  FloorplanPersonalizationNeededSwitch,
  useFloorplanPersonalizationNeededValue,
} from './FloorplanPersonalizationNeededSwitch';
import {
  useAdd,
  useDelete,
  useUpdateFloorplanPersonalizationNeededState,
  useStepDeadlinePassed,
} from './FloorplanPersonalizationPanel';
import classNames from 'classnames';
import styles from './PersonalizationListItem.module.scss';

interface StepPersonalizationListProps {
  itemManagementRestricted: boolean;
  homeId: string;
  communityId: string;
  projectId: string;
  step?: ProjectStepLookupDto;
  compact: boolean | undefined;
  readonly: boolean;
}

const { Text } = Typography;

export function StepPersonalizationList(props: StepPersonalizationListProps) {
  const {
    itemManagementRestricted,
    homeId,
    communityId,
    projectId,
    step,
    compact,
    readonly,
  } = props;
  const { id: stepId } = step ?? {};

  const handleAdd = useAdd(homeId, stepId, projectId);
  const handleDelete = useDelete();
  const handleUpdatePersonalizationNeeded =
    useUpdateFloorplanPersonalizationNeededState(props);

  const checkedValue = useFloorplanPersonalizationNeededValue(
    homeId,
    projectId,
    stepId,
  );

  const isHomeFinalized = useIsFinalized(homeId, projectId);
  const stepDeadlinePassed = useStepDeadlinePassed(homeId, projectId, step);
  const isStepFinalized = useIsFinalized(homeId, projectId, stepId);

  const personalizationNeededSwitchVisible =
    (step && !isStepFinalized) || (!step && !isHomeFinalized);

  const isReadonly =
    readonly ||
    checkedValue === null ||
    checkedValue ||
    checkedValue ||
    stepDeadlinePassed === null ||
    stepDeadlinePassed ||
    !!isStepFinalized;

  return (
    <Space direction="vertical" size="large" className="w-100 m-4">
      <Row align="middle">
        {step && (
          <Col span={compact ? 10 : 3}>
            <div className={classNames(styles.step, 'mb-2')}>
              <Tag color="blue">
                <Text>{step.name}</Text>
              </Tag>
            </div>
          </Col>
        )}
        <Col span={compact ? 14 : 21} className="pb-3">
          <Assert
            permission={CommunityPermissions.Personalization.Manage}
            active
          >
            <>
              {personalizationNeededSwitchVisible && (
                <FloorplanPersonalizationNeededSwitch
                  homeId={homeId}
                  projectId={projectId}
                  handleUpdatePersonalizationNeeded={
                    handleUpdatePersonalizationNeeded
                  }
                  selectedStep={stepId}
                />
              )}
            </>
          </Assert>
        </Col>
      </Row>
      {!itemManagementRestricted && (
        <Row>
          <Space wrap>
            <AddPersonalizationItemButton
              homeId={homeId}
              communityId={communityId}
              floorplanPersonalization
              projectId={projectId}
              step={step}
              onlyGeoItems
              onSubmit={handleAdd}
            />

            <AddSpecialRequestButton
              stepId={stepId}
              homeId={homeId}
              projectId={projectId}
              isFloorplan
            />
          </Space>
        </Row>
      )}

      <PersonalizationList
        className="mb-4 mt-2"
        readonly={isReadonly}
        homeId={homeId}
        projectId={projectId}
        stepId={stepId}
        onDelete={handleDelete}
        compact={compact}
      />
    </Space>
  );
}
