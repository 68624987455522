import {
  useGetSubscriptionDetailsQuery,
  useUpdateSubscriptionMutation,
} from '@/redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notify } from '@/components';
import { SubscriptionDetailsModal } from './SubscriptionDetailsModal';
import { SubscriptionFormValue } from './SubscriptionDetailsForm';
import { SubscriptionType } from '../Subscriptions.List/SubscriptionListTabPanel';

const useSubmit = (closeModal: () => any, id: string) => {
  const [update] = useUpdateSubscriptionMutation();

  return async (values: SubscriptionFormValue) => {
    await update({ ...values, communityId: id })
      .unwrap()
      .then(() => {
        notify.success.t('subscriptions.updated');
        closeModal();
      });
  };
};

export function useEditSubscriptionAction(type: SubscriptionType) {
  const { t } = useTranslation();
  const [id, setId] = useState<string>();
  const { data } = useGetSubscriptionDetailsQuery({ id: id! }, { skip: !id });
  const submit = useSubmit(() => setId(undefined), id!);

  return {
    openEdit: (id: string) => setId(id),
    editModal: (
      <>
        {id && data && (
          <SubscriptionDetailsModal
            title={t('subscriptions.details.editTitle')}
            saveBtnText={t('subscriptions.details.saveBtnText')}
            onSave={submit}
            value={data}
            onCancel={() => setId(undefined)}
            disabledClient={true}
            disabledCommunity={true}
            type={type}
          />
        )}
      </>
    ),
  };
}
