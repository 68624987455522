import { FC } from 'react';
import { MAX_MONEY_VALUE } from '@/utils';
import { FormsNumber, FormsNumberProps } from './FormsNumber';

export type FormsMoneyProps = FormsNumberProps & {
  positive?: boolean;
};

export const FormsMoney: FC<FormsMoneyProps> = (props) => {
  const { positive, ...numberProps } = props;
  const min = positive && numberProps.min === undefined ? 0 : numberProps.min;
  return <FormsNumber {...props} min={min} />;
};

FormsMoney.defaultProps = {
  prefix: '$',
  max: MAX_MONEY_VALUE,
};
