import { rtkq, RtkqRequest } from '../rtkq';
import {
  AddPersonalizationItemDto,
  PersonalizationItemDto,
  UpdatePersonalizationItemDto,
  UpdateGeoPersonalizationItemsPositionDto,
  personalizationsHttp,
  SavePersonalizationItemManualResidentPriceDto,
  PersonalizationHistoryDto,
  PersonalizationSubOptionDto,
  ExistingItemDto,
} from '@/core';
import { keyBy } from 'lodash';
import { api } from '../api';
import { SubOptionState } from '../subOptions';
import { CategoryState } from '../categories';
import { RoomPersonalizationAvailableItemState } from './roomPersonalizationApi';
import { tree } from '@/utils';
import { specialRequestsApi } from '../specialRequests';

export type PersonalizationItemState = PersonalizationItemDto;
export type AddPersonalizationItemArgs = AddPersonalizationItemDto;
export type UpdatePersonalizationItemArgs = UpdatePersonalizationItemDto;
export type UpdateGeoPersonalizationItemsPositionArgs =
  UpdateGeoPersonalizationItemsPositionDto;
export type SavePersonalizationItemManualResidentPriceArgs =
  SavePersonalizationItemManualResidentPriceDto;
export type PersonalizationHistoryState = PersonalizationHistoryDto;
export type PersonalizationSuboptionState = PersonalizationSubOptionDto;
export type ExistingItemState = ExistingItemDto;

const personalizationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPersonalizationItems: build.query<
      PersonalizationItemState[],
      RtkqRequest<{
        homeId: string;
        projectId: string;
        isFloorplan?: boolean;
        stepId?: string | string[];
        roomId?: string;
        wholeHome?: boolean;
        isGeo?: boolean;
      }>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() => personalizationsHttp.getAll(args));
      },
      providesTags: (result = []) => [
        {
          type: 'personalizationItem-category',
          id: result.at(0)?.category.category.id ?? 'never',
        },
        { type: 'personalizationItem', id: 'list' },
        ...result.flatMap(({ id }) => [
          { type: 'personalizationItem' as const, id },
          { type: 'personalization-item-price' as const, id },
        ]),
      ],
    }),

    updateGeoPersonalizationItemsPosition: build.mutation<
      void,
      RtkqRequest<UpdateGeoPersonalizationItemsPositionArgs>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() => personalizationsHttp.updateGeo(args));
      },
      onQueryStarted: (
        { homeId, projectId, items, stepIds, specialRequests },
        { dispatch, queryFulfilled },
      ) => {
        const itemsById = keyBy(items, (x) => x.id);
        const specialRequestsById = keyBy(specialRequests, (x) => x.id);

        const patchStepItemsResult = dispatch(
          personalizationApi.util.updateQueryData(
            'getPersonalizationItems',
            {
              homeId,
              projectId,
              isGeo: true,
              stepId: stepIds,
              isFloorplan: true,
            },
            (draft) => {
              for (const item of draft) {
                const position = itemsById[item.id];
                item.position = position
                  ? { x: position.x, y: position.y }
                  : item.position;
              }
            },
          ),
        );
        const patchAllPersonalizationItemsResults = dispatch(
          personalizationApi.util.updateQueryData(
            'getPersonalizationItems',
            {
              homeId,
              projectId,
              isFloorplan: true,
              stepId: undefined,
              isGeo: true,
            },
            (draft) => {
              for (const item of draft) {
                if (!itemsById.hasOwnProperty(item.id)) {
                  continue;
                }
                const position = itemsById[item.id];
                item.position = position
                  ? { x: position.x, y: position.y }
                  : item.position;
              }
            },
          ),
        );
        const patchStepSpecialRequestsResults = dispatch(
          specialRequestsApi.util.updateQueryData(
            'getSpecialRequests',
            { homeId, projectId, stepId: stepIds, isFloorplan: true },
            (draft) => {
              for (const item of draft) {
                const position = specialRequestsById[item.id];
                item.position = position
                  ? { x: position.x, y: position.y }
                  : item.position;
              }
            },
          ),
        );
        const patchAllSpecialRequestsResults = dispatch(
          specialRequestsApi.util.updateQueryData(
            'getSpecialRequests',
            { homeId, projectId, stepId: undefined, isFloorplan: true },
            (draft) => {
              for (const item of draft) {
                const position = specialRequestsById[item.id];
                item.position = position
                  ? { x: position.x, y: position.y }
                  : item.position;
              }
            },
          ),
        );
        queryFulfilled.catch(() => {
          patchStepItemsResult.undo();
          patchAllPersonalizationItemsResults.undo();
          patchStepSpecialRequestsResults.undo();
          patchAllSpecialRequestsResults.undo();
        });
      },
    }),

    addPersonalizationItem: build.mutation<
      string,
      RtkqRequest<AddPersonalizationItemArgs>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => personalizationsHttp.create(args));
      },
      invalidatesTags: () => [{ type: 'personalizationItem', id: 'list' }],
    }),

    updatePersonalizationItem: build.mutation<
      void,
      RtkqRequest<UpdatePersonalizationItemArgs>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => personalizationsHttp.update(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'personalizationItem', id: args.id },
      ],
    }),

    deletePersonalizationItem: build.mutation<
      void,
      RtkqRequest<{ id: string }>
    >({
      queryFn: (args) => {
        const { id } = args;
        return rtkq(args).exec(() => personalizationsHttp.unlist(id));
      },
      invalidatesTags: (_, __, { id }) => {
        return [{ type: 'personalizationItem', id }];
      },
    }),

    updateFloorplanPersonalizationNeededState: build.mutation<
      void,
      RtkqRequest<{
        homeId: string;
        projectId: string;
        value: boolean;
        stepId?: string;
      }>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() =>
          personalizationsHttp.updateFloorplanPersonalizationNeededState(args),
        );
      },
      invalidatesTags: (_, __, { homeId }) => {
        return [
          { type: 'personalizationItem', id: 'list' },
          { type: 'home', id: homeId },
          { type: 'projectHomeInfo', id: homeId },
          { type: 'special-request', id: 'list' },
        ];
      },
    }),

    updateFinalizationState: build.mutation<
      void,
      RtkqRequest<{
        homeId: string;
        projectId: string;
        value: boolean;
        stepId: string;
      }>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() =>
          personalizationsHttp.updateFinalizationState(args),
        );
      },
      invalidatesTags: (_, __, { homeId, projectId }) => {
        return [
          { type: 'personalizationItem', id: 'list' },
          { type: 'special-request', id: 'project-' + projectId },
          { type: 'projectHomeInfo', id: homeId },
        ];
      },
    }),

    getPersonalizationAvailableSubOptions: build.query<
      SubOptionState[],
      RtkqRequest<{
        homeId: string;
        projectId: string;
        itemId: string;
        includeUnlisted?: string[];
      }>
    >({
      queryFn: async (args) =>
        await rtkq(args).exec(() =>
          personalizationsHttp.getAvailableSubOptions(
            args.homeId,
            args.projectId,
            args.itemId,
            args.includeUnlisted,
          ),
        ),
      providesTags: (result = []) => [
        { type: 'subOption', id: 'list' },
        ...result.map(({ id }) => ({
          type: 'subOption' as const,
          id,
        })),
      ],
    }),

    getPersonalizationCategories: build.query<
      CategoryState[],
      RtkqRequest<{
        homeId: string;
        projectId: string;
        floorplanPersonalization: boolean;
        roomId?: string;
        stepId?: string;
      }>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => personalizationsHttp.getCategories(args));
      },
      providesTags: (result = []) => [
        { type: 'personalizationItem-category', id: 'list' },
        ...tree.flatten(result).map(({ id }) => ({
          type: 'personalizationItem-category' as const,
          id,
        })),
        { type: 'category', id: 'list' },
        ...tree.flatten(result).map(({ id }) => ({
          type: 'category' as const,
          id,
        })),
      ],
    }),

    getPersonalizationAvailableItems: build.query<
      RoomPersonalizationAvailableItemState[],
      RtkqRequest<{
        homeId: string;
        projectId: string;
        roomId?: string;
        stepId?: string;
      }>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() =>
          personalizationsHttp.getAvailableItems({
            ...args,
            floorplanPersonalization: true,
          }),
        );
      },
      providesTags: (result = []) => [
        { type: 'personalizationItem', id: 'list' },
        ...result.map(({ id }) => ({
          type: 'item' as const,
          id,
        })),
        { type: 'item', id: 'list' },
      ],
    }),

    savePersonalizationItemManualResidentPrice: build.mutation<
      void,
      RtkqRequest<SavePersonalizationItemManualResidentPriceArgs>
    >({
      queryFn: (args) =>
        rtkq(args).exec(() =>
          personalizationsHttp.saveManualResidentPrice(args),
        ),
      invalidatesTags: (_, __, { id }) => [{ type: 'personalizationItem', id }],
    }),

    getExistingItems: build.query<
      ExistingItemState[],
      RtkqRequest<{
        homeId: string;
        projectId: string;
        stepId?: string | string[];
        roomId?: string;
      }>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(() =>
          personalizationsHttp.getAllExistingItems(args),
        );
      },
      providesTags: (result = []) => [
        { type: 'room', id: 'list' },
        { type: 'existing-item', id: 'list' },
        ...result.map(({ id }) => ({
          type: 'existing-item' as const,
          id,
        })),
      ],
    }),
  }),
});

export const {
  useGetPersonalizationItemsQuery,
  useUpdateGeoPersonalizationItemsPositionMutation,
  useAddPersonalizationItemMutation,
  useUpdatePersonalizationItemMutation,
  useDeletePersonalizationItemMutation,
  useUpdateFloorplanPersonalizationNeededStateMutation,
  useGetPersonalizationAvailableSubOptionsQuery,
  useGetPersonalizationCategoriesQuery,
  useLazyGetPersonalizationCategoriesQuery,
  useGetPersonalizationAvailableItemsQuery,
  useUpdateFinalizationStateMutation,
  useSavePersonalizationItemManualResidentPriceMutation,
  useGetExistingItemsQuery,
} = personalizationApi;
