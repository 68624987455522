import { isEmpty } from 'lodash';
import * as yup from 'yup';
import { StringSchema } from 'yup';
import { usersHttp } from '../http.users';

function uniqueUser(this: StringSchema) {
  let lastInput: string | null = null;
  let lastResult: boolean = true;

  return this.test('unique-user', { key: 'uniqueUser' }, async (email) => {
    if (lastInput === email) {
      return lastResult;
    }

    if (isEmpty(email)) return true;
    if (!yup.string().email().isValidSync(email)) return true;

    lastInput = email!;
    return (lastResult = !(await usersHttp.exists(email!)));
  });
}

yup.addMethod(yup.string, 'uniqueUser', uniqueUser);
