import { TabPanel, useTabPanelNavMenuState } from '@/components';
import { ProjectStepLookupDto, RoomState, WholeHomeRoomState } from '@/core';
import {
  useGetNotFinalizedRoomPersonalizationRoomIdsListQuery,
  useGetPersonalizationRoomsListQuery,
} from '@/redux';
import { array } from '@/utils';
import { getDevelopScopeRoomId } from '@/views';
import { Col, Row, Space, Switch, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RoomPersonalizationAlert } from './RoomPersonalizationAlert';
import { RoomPersonalizationCategoryList } from './RoomPersonalizationCategoryList';
import { RoomPersonalizationTabNavIcon } from './RoomPersonalizationTabNavIcon';

const { Title } = Typography;

function useTabNav(
  data: RoomState[],
  notFinalizedRoomIds: (string | null)[],
  isSelectedStep: boolean,
) {
  const { t } = useTranslation();

  return useTabPanelNavMenuState({
    items: [
      {
        id: 'null',
        name: t('developScope.wholeHome'),
      } as WholeHomeRoomState,
      ...data,
    ],
    keyBy: (x) => x.id,
    iconBy: (x) =>
      isSelectedStep && (
        <RoomPersonalizationTabNavIcon
          isAlert={notFinalizedRoomIds.includes(getDevelopScopeRoomId(x.id)!)}
        />
      ),
    titleBy: (x) => x.name,
  });
}

interface RoomPersonalizationPanelProps {
  communityId: string;
  homeId: string;
  projectId: string;
  selectedRoomId?: string;
  compact?: boolean;
  steps?: ProjectStepLookupDto[] | null;
  isWholeHomeSelected?: boolean;
  onRoomSelect: (roomId?: string) => void;
}

export function RoomPersonalizationPanel(props: RoomPersonalizationPanelProps) {
  const {
    communityId,
    homeId,
    projectId,
    selectedRoomId: initialSelectedRoomId,
    compact,
    steps,
    isWholeHomeSelected,
    onRoomSelect,
  } = props;

  const [collapsed, setCollapsed] = useState(false);
  const { t } = useTranslation();

  const { data = array.empty<RoomState>() } =
    useGetPersonalizationRoomsListQuery({
      homeId,
      projectId,
    });

  const { data: notFinalizedRoomIds = array.empty<string>() } =
    useGetNotFinalizedRoomPersonalizationRoomIdsListQuery(
      {
        communityId,
        projectId,
        homeId: homeId,
        stepId: steps ? steps[0].id : '',
      },
      {
        skip: !steps || isWholeHomeSelected || steps.length !== 1,
      },
    );

  const tabNav = useTabNav(
    data,
    notFinalizedRoomIds,
    steps?.length === 1 && !isWholeHomeSelected,
  );
  const { selected, items } = tabNav;

  const selectedRoomId =
    getDevelopScopeRoomId(initialSelectedRoomId || selected?.id) || undefined;

  const selectedRoomName = items.find(
    (x) => x.id === (initialSelectedRoomId || selected?.id),
  )?.name;

  useEffect(() => {
    onRoomSelect(selectedRoomId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoomId]);

  return (
    <>
      {!isWholeHomeSelected &&
        steps?.length === 1 &&
        notFinalizedRoomIds.length === 0 && <RoomPersonalizationAlert />}

      <Space direction="vertical" size="large" className="w-100">
        <TabPanel>
          {homeId && !compact && (
            <TabPanel.Nav title={t('developScope.navTitle')}>
              <TabPanel.Menu {...tabNav} />
            </TabPanel.Nav>
          )}
          <TabPanel.Body>
            {selected && selectedRoomName && steps !== null && (
              <>
                <Row>
                  <Col span={18}>
                    <Title level={4}>{selectedRoomName}</Title>
                  </Col>
                  <Col span={compact ? 24 : 6} className="text-end">
                    <Space>
                      <Switch
                        checked={collapsed}
                        onChange={() => setCollapsed(!collapsed)}
                      />
                      {t(
                        collapsed
                          ? 'personalization.item.details.expand'
                          : 'personalization.item.details.collapse',
                      )}
                    </Space>
                  </Col>
                </Row>
                {steps?.map((step, index) => {
                  return (
                    <RoomPersonalizationCategoryList
                      key={selectedRoomId}
                      homeId={homeId}
                      projectId={projectId}
                      step={step}
                      roomId={selectedRoomId}
                      collapsed={collapsed}
                      compact={compact}
                      className={index !== steps.length - 1 ? 'mb-2' : ''}
                    />
                  );
                })}
                {!steps && (
                  <RoomPersonalizationCategoryList
                    key={selectedRoomId}
                    homeId={homeId}
                    projectId={projectId}
                    roomId={selectedRoomId}
                    collapsed={collapsed}
                    compact={compact}
                  />
                )}
              </>
            )}
          </TabPanel.Body>
        </TabPanel>
      </Space>
    </>
  );
}
