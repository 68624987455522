import { Form, PdfDownloadButton } from '@/components';
import { Button, Divider, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import {
  DownloadPdfButtonProps,
  useDownloadPdf,
  useDownloadTabloidPdf,
  DownloadPdfArgs,
} from './DownloadPdfButton';
import i18next from 'i18next';
import { Language } from '@/core';

interface PdfModalProps extends DownloadPdfButtonProps {
  open: boolean;
  toggleModalOpen: () => any;
}

export const ORIENTATION_OPTIONS = [
  {
    value: 'Portrait',
    label: i18next.t<string>(
      'personalization.downloadPdf.orientation.portrait',
    ),
  },
  {
    value: 'Landscape',
    label: i18next.t<string>(
      'personalization.downloadPdf.orientation.landscape',
    ),
  },
];

export const SIZE_OPTIONS = [
  {
    value: 'Letter',
    label: i18next.t<string>('personalization.downloadPdf.size.letter'),
  },
  {
    value: 'Tabloid',
    label: i18next.t<string>('personalization.downloadPdf.size.tabloid'),
  },
];

export const LANGUAGE_OPTIONS = [
  {
    value: Language.English,
    label: i18next.t<string>('enums.Language.English'),
  },
  {
    value: Language.Spanish,
    label: i18next.t<string>('enums.Language.Spanish'),
  },
];

export function DownloadPdfFormModal(props: PdfModalProps) {
  const {
    open,
    toggleModalOpen,
    projectId,
    homeId,
    showBaseCost,
    showResidentPrice,
    selectedStepId,
    showResidentInfo,
  } = props;
  const { t } = useTranslation();

  const { values, resetForm } = useFormikContext<DownloadPdfArgs>();
  const isLetterSize = useMemo(() => values.size === 'Letter', [values]);

  const downloadPdf = useDownloadPdf(
    projectId,
    homeId,
    showBaseCost,
    showResidentPrice,
    showResidentInfo,
    values.orientation,
    values.language,
    values.showConstruction,
    selectedStepId,
  );

  const downloadTabloidPdf = useDownloadTabloidPdf(
    projectId,
    homeId,
    values.language,
    showResidentInfo,
    selectedStepId,
  );

  const onCancel = () => {
    resetForm();
    toggleModalOpen();
  };

  return (
    <Modal
      title={t('downloadPdf')}
      visible={open}
      footer={[
        <Button key="back" type="default" onClick={onCancel}>
          {t('cancel')}
        </Button>,
        <PdfDownloadButton
          action={isLetterSize ? downloadPdf : downloadTabloidPdf}
        />,
      ]}
      onCancel={onCancel}
    >
      <Form.Radio
        name="size"
        options={SIZE_OPTIONS}
        label={t('personalization.downloadPdf.size.title')}
        inline={false}
      />
      <Divider />
      {isLetterSize && (
        <>
          <Form.Radio
            name="orientation"
            options={ORIENTATION_OPTIONS}
            label={t('personalization.downloadPdf.orientation.title')}
            inline={false}
          />
          <Divider />
        </>
      )}
      <Form.Radio
        name="language"
        options={LANGUAGE_OPTIONS}
        label={t('personalization.downloadPdf.language')}
        inline={false}
      />
      <Divider />
      {isLetterSize && (
        <>
          <Form.Checkbox
            name="showConstruction"
            label={t('personalization.downloadPdf.showConstruction')}
          />
        </>
      )}
    </Modal>
  );
}
