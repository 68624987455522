import { Form } from '@/components';
import { useProjectLookupSource } from '@/views';
import { useField } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
  communityIds?: string[];
}

export function ActivityProjectSelect(props: Props) {
  const { name, communityIds } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'activity.table.filter.placeholders',
  });
  const projectDataSource = useProjectLookupSource(communityIds);
  const [, , { setValue }] = useField(name);

  useEffect(() => {
    setValue(undefined);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityIds]);

  return (
    <Form.AsyncSelect
      key={communityIds?.join()}
      label={false}
      dataSource={projectDataSource}
      name={name}
      placeholder={t('project')}
      mode="multiple"
    />
  );
}
