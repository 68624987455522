import { enumName } from '@/utils';
import { useTranslation } from 'react-i18next';

export function EnumValue({ type, value }: { type: any; value: any }) {
  const { t } = useTranslation();
  const text = getEnumValue(type, value, t);
  return text ? <>{text}</> : null;
}

/**
 * Returns a localized value from enum.
 * @param type - Enum name
 * @param value - Value which should be localized
 * @param t - Translator
 */
export const getEnumValue = (type: any, value: any, t: any): string => {
  const name = enumName(type);
  return value ? t(`enums.${name}.${value}`) : null;
};
