import { CategoryLookupDto, RoomLookupDto } from '@/core';
import {
  ExistingItemState,
  PersonalizationItemState,
  RtkqSpin,
  SpecialRequestState,
  useGetExistingItemsQuery,
  useGetPersonalizationItemsQuery,
  useGetSpecialRequestsQuery,
} from '@/redux';
import { array } from '@/utils';
import { Typography } from 'antd';
import { chain, isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PersonalizationItemList,
  useGetPersonalizationItemsSubtotalAmount,
} from './PersonalizationItemList';
import { usePersonalizationColumns } from './usePersonalizationListColumns';
import styles from './PersonalizationSummary.module.scss';
import { PersonalizationSummarySubTotalInfo } from './PersonalizationSummarySubtotalInfo';
import { PersonalizationSummaryHomeTotaBalance } from './PersonalizationSummaryHomeTotalBalance';

interface Props {
  homeId: string;
  projectId: string;
  stepId?: string;
  className?: string;
  showBaseCost: boolean;
  showResidentPrice: boolean;
}

export interface PersonalizationTableRowValues {
  number?: number;
  room?: RoomLookupDto;
  isFloorplan: boolean;
  name: string;
  stepName: string;
  categoryPath: CategoryLookupDto[];
  description?: string;
  totalResidentPrice?: number;
  totalBaseCost?: number;
  specialRequest?: SpecialRequestState;
  personalizationItem?: PersonalizationItemState;
  isExistingItem?: boolean;
}

export function usePersonalizationData(props: {
  homeId: string;
  projectId: string;
  stepId?: string | string[];
}) {
  const { homeId, projectId, stepId } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'personalization.summary',
  });

  const { data = array.empty<PersonalizationItemState>(), isLoading } =
    useGetPersonalizationItemsQuery({
      homeId,
      projectId,
      stepId,
      [RtkqSpin]: false,
    });

  const { data: specialRequests = array.empty<SpecialRequestState>() } =
    useGetSpecialRequestsQuery({
      projectId,
      homeId,
      stepId,
      [RtkqSpin]: false,
    });

  const { data: existingItems = array.empty<ExistingItemState>() } =
    useGetExistingItemsQuery({
      projectId,
      homeId,
      stepId,
      [RtkqSpin]: false,
    });

  const specialRequestRowValues = useMemo<PersonalizationTableRowValues[]>(
    () =>
      specialRequests.map((x) => ({
        name: x.name,
        stepName: x.step.name,
        room: x.room,
        isFloorplan: x.isFloorplan,
        totalResidentPrice:
          x.status === 'ConstructionApproved'
            ? x.prices?.residentPrice
            : undefined,
        totalBaseCost:
          x.status === 'ConstructionApproved' ? x.prices?.baseCost : undefined,
        description: x.description,
        categoryPath: x.category.path,
        specialRequest: x,
        number: x.number,
      })),
    [specialRequests],
  );

  const personalizationItemRowValues = useMemo<PersonalizationTableRowValues[]>(
    () =>
      data.map((x) => ({
        name: x.name,
        number: x.number,
        room: x.room,
        isFloorplan: x.isFloorplan,
        totalResidentPrice: x.pricing.totalResidentPrice,
        totalBaseCost: x.pricing.totalBaseCost,
        stepName: x.step.name,
        description: x.description,
        categoryPath: x.category.path,
        personalizationItem: x,
      })),
    [data],
  );

  const existingItemRowValues = useMemo<PersonalizationTableRowValues[]>(
    () =>
      existingItems.map((x) => ({
        name: t('existingItem', { name: x.name }),
        room: x.room,
        isFloorplan: false,
        stepName: x.step.name,
        description: '',
        categoryPath: x.category.path,
        isExistingItem: true,
      })),
    [existingItems, t],
  );

  const values: PersonalizationTableRowValues[] = [
    ...personalizationItemRowValues,
    ...specialRequestRowValues.filter(
      (x) => x.specialRequest?.status !== 'Denied',
    ),
    ...existingItemRowValues,
  ];

  const floorplanData = values.filter((x) => x.isFloorplan);

  const wholeHomeData = values.filter((x) => !x.isFloorplan && !x.room);

  const roomsData = chain(values)
    .filter((x) => !x.isFloorplan && !!x.room)
    .groupBy((x) => x.room?.id!)
    .map((value) => ({
      roomId: value.at(0)!.room?.id,
      roomName: value.at(0)!.room?.name,
      roomCreatedAt: value.at(0)!.room?.createdAt,
      value,
    }))
    .sort((a, b) =>
      (a.roomCreatedAt || '').localeCompare(b.roomCreatedAt ?? ''),
    )
    .value();

  return {
    loading: isLoading,
    totalHomeData: values,
    floorplanData,
    wholeHomeData,
    roomsData,
    allPersonalizations: values,
    inactiveData: specialRequestRowValues.filter(
      (x) => x.specialRequest?.status === 'Denied',
    ),
  };
}

export function PersonalizationDetails(props: Props) {
  const { className, showBaseCost, showResidentPrice } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'personalization.summary',
  });

  const floorplanColumns = usePersonalizationColumns(
    true,
    showResidentPrice,
    showBaseCost,
    false,
  );
  const roomColumns = usePersonalizationColumns(
    false,
    showResidentPrice,
    showBaseCost,
    false,
  );
  const inactiveColumns = usePersonalizationColumns(false, false, false, true);

  const {
    loading,
    floorplanData,
    wholeHomeData,
    roomsData,
    allPersonalizations,
    inactiveData,
  } = usePersonalizationData(props);

  const { amount: roomItemsResidentPriceSubtotalAmount } =
    useGetPersonalizationItemsSubtotalAmount(
      roomsData.flatMap((x) => x.value),
      (x) => x.totalResidentPrice,
      showResidentPrice,
    );

  const { amount: roomItemsBaseCostSubtotalAmount } =
    useGetPersonalizationItemsSubtotalAmount(
      roomsData.flatMap((x) => x.value),
      (x) => x.totalBaseCost,
      showBaseCost,
    );

  return (
    <div>
      {!isEmpty(floorplanData) && (
        <PersonalizationItemList
          className="mb-5 w-100"
          columns={floorplanColumns}
          dataSource={floorplanData}
          name="FloorplanPersonalizations"
          title={t('floorplanPersonalizations')}
          subtotalTitle={t('subtotalTitles.floorplanPersonalizations')}
          loading={loading}
          showBaseCost={showBaseCost}
          showResidentPrice={showResidentPrice}
        />
      )}

      {!isEmpty(wholeHomeData) && (
        <PersonalizationItemList
          className="mb-5 w-100"
          columns={roomColumns}
          dataSource={wholeHomeData}
          name="WholeHomePersonalizations"
          title={t('wholeHomePersonalizations')}
          subtotalTitle={t('subtotalTitles.wholeHomePersonalizations')}
          loading={loading}
          showBaseCost={showBaseCost}
          showResidentPrice={showResidentPrice}
        />
      )}

      {!isEmpty(roomsData) && (
        <div className={className}>
          <div className="mb-3">
            <Typography.Title level={4} className={styles.title}>
              {t('roomPersonalizations')}
            </Typography.Title>
          </div>
          {roomsData.map((group) => (
            <PersonalizationItemList
              className="w-100 mb-3"
              key={group.roomName}
              columns={roomColumns}
              dataSource={group.value}
              name={`Room_${group.roomName}`}
              subTitle={group.roomName}
              loading={loading}
            />
          ))}
          <PersonalizationSummarySubTotalInfo
            title={t('subtotalTitles.roomPersonalizations')}
            baseCostSubtotal={roomItemsBaseCostSubtotalAmount}
            residentPriceSubtotal={roomItemsResidentPriceSubtotalAmount}
          />
        </div>
      )}
      {!isEmpty(inactiveData) && (
        <PersonalizationItemList
          className="mb-5 w-100"
          columns={inactiveColumns}
          dataSource={inactiveData}
          name="InactivePersonalizations"
          title={t('inactivePersonalizations')}
          loading={loading}
        />
      )}
      {!isEmpty(allPersonalizations) && (
        <PersonalizationSummaryHomeTotaBalance
          allPersonalizations={allPersonalizations}
          showBaseCost={showBaseCost}
          showResidentPrice={showResidentPrice}
        />
      )}
    </div>
  );
}
