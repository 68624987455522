import { FC, PropsWithChildren } from 'react';
import { NavPanel } from './NavPanel';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Col, Layout as AntdLayout, Row, Space } from 'antd';
import classes from './Layout.module.scss';
import { Content, Footer, Header } from 'antd/lib/layout/layout';

export const Layout: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <AntdLayout className={classes.layout}>
      <Header className={classes.header}>
        <NavPanel />
      </Header>

      <Content className={classes.main}>{children}</Content>

      <Footer className={classes.footer}>
        <Row justify="space-between">
          <Col>{t('copyright', { year: new Date().getFullYear() })}</Col>
          <Col>
            <Space split="|" className="nav-link-space">
              <NavLink to="/about-us">{t('aboutUs')}</NavLink>
              <NavLink to="/terms-and-conditions">{t('terms')}</NavLink>
            </Space>
          </Col>
        </Row>
      </Footer>
    </AntdLayout>
  );
};
