import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { guard } from '@/utils';
import load from '@/assets/images/load.gif';

import classes from './Spinner.module.scss';

export const Spinner: FC = () => {
  const [show, setShow] = useState(spinner.value);

  useEffect(() => {
    spinner.subscribe(setShow);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!show) {
    return <></>;
  }

  return (
    <div className={classNames(classes.fade, classes.show)}>
      <div className={classes.container}>
        <img alt="Loading..." src={load} />
      </div>
    </div>
  );
};

class SpinnerManager {
  private _count: number = 0;
  private _subscriber!: (show: boolean) => any;

  public subscribe = (subscriber: (show: boolean) => any) => {
    guard.notNull(subscriber, 'subscriber');
    this._subscriber = subscriber;
  };

  public get value() {
    return this._count > 0;
  }

  public show = () => {
    const value = this.value;
    this._count++;

    if (value !== this.value) this._subscriber && this._subscriber(this.value);
  };

  public hide = () => {
    const value = this.value;
    this._count--;

    if (value !== this.value) this._subscriber && this._subscriber(this.value);
  };

  public wrap = async <T extends any>(fn: () => Promise<T>): Promise<T> => {
    try {
      this.show();
      return await fn();
    } finally {
      this.hide();
    }
  };
}

export const spinner = new SpinnerManager();
