import { Page } from '@/components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  HomesOfferingsReportButtonPanel,
  ProjectHomePersonalizationPanel,
  SearchProjectHomeInput,
  useHomeSelectedState,
} from '@/views';
import { useGetProjectQuery } from '@/redux';
import { Col, Row, Space } from 'antd';
import styles from './ProjectHomePersonalizationPage.module.scss';
import { FormatPainterOutlined } from '@ant-design/icons';

export function ProjectHomePersonalizationPage() {
  const { t } = useTranslation();
  const { id } = useParams<'id'>();
  const selectedState = useHomeSelectedState();
  const { selectedId: selectedHomeId } = selectedState;

  const { data: project } = useGetProjectQuery({ id: id! }, { skip: !id });
  const { communityId } = project ?? {};

  return (
    <Page.Card
      title={
        <Row align="middle">
          <Col>{t('personalization.homeTitle')}</Col>
        </Row>
      }
      actions={
        <Space size="large">
          {communityId && (
            <SearchProjectHomeInput
              communityId={communityId}
              projectId={id}
              showResidentNames
              className={styles.homeInput}
              {...selectedState}
            />
          )}
          {selectedHomeId && project && (
            <HomesOfferingsReportButtonPanel
              projectId={project.id}
              homeId={selectedHomeId}
            />
          )}
        </Space>
      }
      icon={<FormatPainterOutlined style={{ fontSize: '20px' }} />}
      color="#EFAC4E"
      className={styles.card}
    >
      {id && (
        <ProjectHomePersonalizationPanel
          projectId={id!}
          selectedHomeId={selectedHomeId}
        />
      )}
    </Page.Card>
  );
}
